import { Fragment, useContext, useState } from 'react';
//import ms from 'ms';
import { toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';

import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';

import api from '../utils/api';
import fields from '../utils/fields';
import tokenRefresh from '../utils/tokenRefresh';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationArrow,
  faPlusCircle,
  faSync,
  faTimes,
  faTimesCircle,
  faUsers,
  faUsersCog
} from '@fortawesome/free-solid-svg-icons';

const spacer = <>&nbsp;</>;

const iconLocationArrow = <FontAwesomeIcon icon={faLocationArrow} />;
const iconPlusCircle = <FontAwesomeIcon icon={faPlusCircle} />;
const iconSync = <FontAwesomeIcon icon={faSync} />;
const iconTimes = <FontAwesomeIcon icon={faTimes} />;
const iconTimesCircle = <FontAwesomeIcon icon={faTimesCircle} />;
const iconUsersAlt = <FontAwesomeIcon icon={faUsers} />;
const iconUsersEdit = <FontAwesomeIcon icon={faUsersCog} />;

export default function FamilyEditModal(props) {
  const [ host ] = useContext(HostContext);
  const [ user, setUser ] = useContext(UserContext);
  const [ showModal, setShowModal ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isRefreshing, setIsRefreshing ] = useState(false);
  const [ isUpdated, setIsUpdated ] = useState(false);
  // use stringify/parse to clone object safely
  const [ family, setFamily ] = useState({
    ...JSON.parse(JSON.stringify(props.family || {}))/*,
    ...{testUnknownField: 'sure'}*/
  });

  let validationErrors = [];

  let familySize = props.members ? Object.keys(props.members).length : 0;
  let familyElements = [];
  if (familySize > 1) {
    familyElements.push(
        <span
          key={`family_header_${family.familyId}`}
          className="font-bold">Family members:</span>
    );
    // sort props.members
    let familyMemberIdArray = Object.keys(props.members);
    familyMemberIdArray.sort((a, b) => {
      // !dependent before dependent
      if (props.members[a].isDependant) {
        if (!props.members[b].isDependant) {
          // b before a
          return 1;
        }
      } else {
        if (props.members[b].isDependant) {
          // a before b
          return -1;
        }
      }
      // member familyName matching the family familyName,
      if (props.members[a].familyName === family.familyName) {
        if (props.members[b].familyName !== family.familyName) {
          // a before b
          return -1;
        }
      } else {
        if (props.members[b].familyName === family.familyName) {
          // b before a
          return 1;
        }
      }
      // sort alphabetically
      return props.members[a].firstNames.localeCompare(props.members[b].firstNames);
    });
    for (let i in familyMemberIdArray) {
      let memberId = familyMemberIdArray[i];
      let member = props.members[memberId];
      let title = props.members[memberId].title && props.members[memberId].title.length > 0 ? `${props.members[memberId].title} ` : '';
      let dependence = props.members[memberId].isDependant ? ` (dependant)` : '';
      familyElements.push(
        <Fragment key={`fam_${member.memberId}`}>
          <br />
          <i>{props.members[memberId].familyName}, {title}{props.members[memberId].firstNames}{dependence}</i>
        </Fragment>
      );
    }
  }

  const cancel = (mustClose) => {
    // use stringify/parse to clone object safely
    console.log(`closing (${mustClose})`);
    setFamily(JSON.parse(JSON.stringify(props.family || {})));
    setIsUpdated(false);
    if (mustClose) {
      setShowModal(false);
    }
  };

  //console.log(`session token: ${ms(user.authTokenExpiration - Date.now())} remaining`);

  const refreshAuthToken = () => {
    return new Promise(async (resolve, reject) => {
      tokenRefresh.refresh({ host, user })
      .then((userSession) => {
        setUser(userSession);
        console.log(`refresh complete`);
        resolve();
      })
      .catch(err => {
        toast.error(err);
        setUser({});
        reject();
      });
    });
  };

  const refreshFamily = async () => {
    setIsRefreshing(true);
    let json = await (
      await fetch(
        api.family(host.url, family.familyId),
        api.formatRequest({ method: "GET", authToken: user.authToken })
      )
    ).json();
    if (json.reason) {
      switch (json.reason) {
        case "Invalid/expired authentication token":
          await refreshAuthToken();
          refreshFamily();
          return;
        default:
          toast.error(json.reason);
      }
    } else {
      setFamily(json);
      setIsUpdated(false);
      props.onUpdate(json);
    }
    setIsRefreshing(false);
  };

  const save = async () => {
    if (validationErrors.length > 0) {
      toast.error(`Please review validation errors.`);
      console.error(validationErrors);
      return;
    }
    setIsSaving(true);
    try {
      // update family, on success hide modal and call "onUpdate" with the response body
      let json = await (
        await fetch(
          api.family(host.url, family.familyId),
          api.formatRequest({
            method:"PUT",
            body: family,
            authToken: user.authToken
          })
        )
      ).json();
      if (json.reason) {
        switch (json.reason) {
          case "Invalid/expired authentication token":
            await refreshAuthToken();
            save();
            return;
          case "Family details outdated":
            toast.warn("Family details outdated, reloading...");
            refreshFamily();
            break;
          default:
            toast.error(json.reason);
        }
      } else {
        setIsUpdated(false);
        setFamily(json);
        props.onUpdate(json);
      }
    } catch (err) {
      toast.error(err);
    }
    setIsSaving(false);
  };

  const createInputLabel = (key, name) => {
    let cleanKey = key.replace(" ", "_");
    return (
      <label className="block text-grey-800 text-sm font-bold mb-2" htmlFor={cleanKey}>
          {name}
      </label>
    );
  };

  const updateFamilyValue = (key, value) => {
    let updatedFamily = {
      ...family,
      [key]: value,
    };
    setFamily(updatedFamily);
    setIsUpdated(true);
  };

  const createInputElement = (key, keyType, name) => {
    let cleanKey = key.replace(" ", "_");
    switch (keyType) {
      case "string":
        //fallthrough
      case "number":
        // TODO cast input or show error
        return (
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-800"
            key={cleanKey}
            id={cleanKey}
            type="text"
            placeholder={name || key}
            onChange={e => { updateFamilyValue(key, e.target.value); }}
            value={family[key]}
            disabled={props.readOnly}
          ></input>
        );
      case "boolean":
        return (
          <div
            key={cleanKey}
            id={cleanKey}
          >
            {name}
            {spacer}
            <input className="form-checkbox"
              type="checkbox"
              checked={family[key]}
              onChange={e => { updateFamilyValue(key, e.target.checked); }}
            />
          </div>
        );
        case "array":
          return (
            <div
              key={cleanKey}
              id={cleanKey}
            >
              <MultiSelect
                options={fields.getSeenValues(key).map(val=>{
                  return {
                    label: val,
                    value: val,
                  };
                })}
                value={(family[key] || []).map(val=>{
                  return {
                    label: val,
                    value: val,
                  };
                })}
                onChange={(selectedTags) => {
                  selectedTags = selectedTags.map(obj => obj.value);
                  for (let i in selectedTags) {
                    fields.addSeenValue(key, selectedTags[i]);
                  }
                  updateFamilyValue(key, selectedTags);
                }}
                labelledBy="Select Tag"
                disabled={props.readOnly}
                isCreatable={true}
                onCreateOption={(value) => ({
                    label: value,
                    value: value
                })}
              />
            </div>
          );
        default:
        return (
          <i>Unable to provide form field for <b>{fields.getName({ host, field: key})}</b>.</i>
        );
    }
  };

  let familyFormFields = [];

  // TODO form validation, contact details, different field types, saving

  // build out required fields, then loop through any keys that haven't been used
  let managedFields = [
    'addresses',
    'contactDetails',
    'dataCodes',
    'familyId',
    'familyName',
    'memorials',
    'subsWrittenOff',
    'updated',
  ];
  familyFormFields.push(
    <div key={`familyName_${family.familyId}`} className="mb-4">
      {createInputLabel('familyName', fields.getName({ host, field: 'familyName'}))}
      {createInputElement('familyName', 'string', 'Family name')}
    </div>
  );

  // CONTACT DETAILS
  const createContactTypeSelect = (i) => {
    return (
      <select
        key={`contactDetail_${i}_type`}
        id={`contactDetail_${i}_type`}
        value={family.contactDetails[i].contactType}
        className="bg-transparent w-24"
        onChange={(event) => {
          let tempContactDetails = family.contactDetails;
          tempContactDetails[i].contactType = event.target.value;
          updateFamilyValue('contactDetails', tempContactDetails);
        }}
        disabled={props.readOnly}
      >
        <option value={`email`}>email</option>
        <option value={`mobile`}>mobile</option>
        <option value={`telephone`}>telephone</option>
      </select>
    );
  };

  const createContactNameInput = (i) => {
    return (
      <input className="shadow appearance-none border rounded w-36 py-2 px-3 text-grey-800"
        key={`contactDetail_${i}_name`}
        id={`contactDetail_${i}_name`}
        type="text"
        placeholder={`Name eg. "home" or "work"`}
        onChange={(event) => {
          let tempContactDetails = family.contactDetails;
          tempContactDetails[i].name = event.target.value;
          updateFamilyValue('contactDetails', tempContactDetails);
        }}
        value={family.contactDetails[i].name}
        disabled={props.readOnly}
      ></input>
    );
  };

  const createContactDetailInput = (i) => {
    return (
      <input className="shadow appearance-none border rounded w-72 py-2 px-3 text-grey-800"
        key={`contactDetail_${i}_detail`}
        id={`contactDetail_${i}_detail`}
        type="text"
        placeholder={"Email address or telephone number"}
        onChange={(event) => {
          let tempContactDetails = family.contactDetails;
          tempContactDetails[i].detail = event.target.value;
          updateFamilyValue('contactDetails', tempContactDetails);
        }}
        value={family.contactDetails[i].detail}
        disabled={props.readOnly}
      ></input>
    );
  };

  let contactDetailsEntries = [];
  for (let i in family.contactDetails) {
    contactDetailsEntries.push(
      <div key={`contact_${family.familyId}_${i}`} className=" pb-3">
        {createContactTypeSelect(i)}
        {spacer}
        {createContactNameInput(i)}
        {spacer}
        {createContactDetailInput(i)}
        {spacer}
        {props.readOnly ? null : <button
            className="p-4 bg-red-400 hover:bg-red-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
            disabled={props.readOnly}
            onClick={() => {
              let tempContactDetails = family.contactDetails;
              tempContactDetails.splice(i, 1);
              updateFamilyValue('contactDetails', tempContactDetails);
            }}
        >
            {iconTimesCircle}
        </button>}
      </div>
    );
  }
  // + to add a contact detail
  let contactDetailsElements = (
    <div key={`contact_${family.familyId}`} className="block text-grey-800 text-sm font-bold mb-2">
      Contact Details
      {spacer}
      {props.readOnly ? null : <button
          className="pb-3"
          disabled={isRefreshing}
          onClick={() => {
            let tempContactDetails = family.contactDetails || [];
            tempContactDetails.push({
              contactType: 'email',
              detail: '',
              name: '',
            });
            updateFamilyValue('contactDetails', tempContactDetails);
          }}
        >
          {iconPlusCircle}
      </button>}
      <br />
      {contactDetailsEntries}
    </div>
  );
  familyFormFields.push(contactDetailsElements);

  // ADDRESSES
  let addressFormat = {
    street: 'Street Address',
    unit: 'Unit',
    city: 'City / Town',
    state: 'State / Province / County',
    postCode: 'Zip / Postal Code',
    country: 'Country'

  };
  const createAddressInput = (i) => {
    let addressFields = [];
    for (let key in addressFormat) {
      addressFields.push(
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-800"
          key={`address_${i}_detail_${key}`}
          id={`address_${i}_detail_${key}`}
          type="text"
          placeholder={addressFormat[key]}
          onChange={(event) => {
            // stringify + parse to deep clone with no references
            let tempAddresses = JSON.parse(JSON.stringify(family.addresses));
            tempAddresses[i].detail[key] = event.target.value;
            updateFamilyValue('addresses', tempAddresses);
          }}
          value={family.addresses[i].detail[key]}
          disabled={props.readOnly}
        ></input>
      );
    }
    return (
      <div>
        {addressFields}
      </div>
    );
  };

  const createAddressNameInput = (i) => {
    // validation
    let errorMessage;
    if (!family.addresses[i].name
      || family.addresses[i].name.length === 0) {
      errorMessage = "Address names cannot be empty.";
    } else {
      for (let oa in family.addresses) {
        if (oa !== i
          && family.addresses[oa].name === family.addresses[i].name) {
            errorMessage = "Address names must be unique.";
          }
      }
    }
    let errorElement;
    if (errorMessage) {
      errorElement = <p className="text-red-500 text-xs italic">{errorMessage}</p>;
      if (validationErrors.indexOf(errorMessage) < 0) {
        validationErrors.push(errorMessage);
      }
    }
    return (
      <>
        <input className="shadow appearance-none border rounded w-36 py-2 px-3 text-grey-800"
          key={`address_${i}_name`}
          id={`address_${i}_name`}
          type="text"
          placeholder={`Name eg. "home" or "work"`}
          onChange={(event) => {
            // stringify + parse to deep clone with no references
            let tempAddresses = JSON.parse(JSON.stringify(family.addresses));
            tempAddresses[i].name = event.target.value;
            updateFamilyValue('addresses', tempAddresses);
          }}
          value={family.addresses[i].name}
          disabled={props.readOnly}
        ></input>
        {errorElement}
      </>
    );
  };

  let addressEntries = [];
  for (let i in family.addresses) {
    addressEntries.push(
      <div key={`address_${family.familyId}_${i}`} className="pb-3">
        {createAddressNameInput(i)}
        {spacer}
        <button
            className="bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
            disabled={Object.values(family.addresses[i].detail).join("").length === 0}
            onClick={() => {
              window.open(
                `https://www.google.com/maps/search/${Object.values(family.addresses[i].detail).join()}`,
                '_blank'
              );
            }}
        >
            {iconLocationArrow}
        </button>
        {spacer}
        {props.readOnly ? null : <button
            className="p-4 bg-red-400 hover:bg-red-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
            disabled={props.readOnly}
            onClick={() => {
              // stringify + parse to deep clone with no references
              let tempAddresses = JSON.parse(JSON.stringify(family.addresses));
              tempAddresses.splice(i, 1);
              updateFamilyValue('addresses', tempAddresses);
            }}
        >
            {iconTimesCircle}
        </button>}
        {spacer}
        {createAddressInput(i)}
      </div>
    );
  }

  // + to add an address
  let addressElements = (
    <div key={`addresses_${family.familyId}`} className="block text-grey-800 text-sm font-bold mb-2">
      Address{family.addresses && family.addresses.length > 0 ? 'es': ''}
      {spacer}
      {props.readOnly ? null : <button
          className="pb-3"
          disabled={isRefreshing}
          onClick={() => {
            // stringify + parse to deep clone with no references
            let tempAddresses = family.addresses ?
              JSON.parse(JSON.stringify(family.addresses)) :
              [];
            tempAddresses.push({
              detail: {
                street: '',
                unit: '',
                city: '',
                state: '',
                postCode: '',
                country: ''
              },
              name: '',
            });
            updateFamilyValue('addresses', tempAddresses);
          }}
        >
          {iconPlusCircle}
      </button>}
      <br />
      {addressEntries}
    </div>
  );
  familyFormFields.push(addressElements);

  // data codes / tags
  familyFormFields.push(
    <div key={`dataCodes_${family.familyId}`} className="mb-4">
      {createInputLabel('dataCodes', fields.getName({ host, field: 'dataCodes'}))}
      {createInputElement('dataCodes', 'array', 'Data Codes/Tags')}
    </div>
  );

  // handle any unmanaged fields
  for (let key in family) {
    if (managedFields.indexOf(key) < 0) {
      let labelElement = createInputLabel(key, fields.getName({ host, field: key}));
      let inputElement = createInputElement(key, typeof family[key], fields.getName({ host, field: key}));
      if (inputElement) {
        familyFormFields.push(
          <div key={`unmanaged_${key}_${family.familyId}`} className="mb-4">
            {labelElement}
            {inputElement}
          </div>
        );
      }
    }
  }

  // subs written off
  familyFormFields.push(
    <div key={`subsWrittenOff_${family.familyId}`} className="mb-4">
      {createInputLabel('subsWrittenOff', fields.getName({ host, field: 'subsWrittenOff'}))}
      {createInputElement('subsWrittenOff', 'array', 'Subscriptions Written Off')}
    </div>
  );

  // memorials
  familyFormFields.push(
    <div key={`memorials_${family.familyId}`} className="mb-4">
      {createInputLabel('memorials', fields.getName({ host, field: 'memorials'}))}
      {createInputElement('memorials', 'array', 'Memorials', true)}
    </div>
  );

  const editFamilyButton = (
    <button
      className="bg-blue-400 hover:bg-blue-800 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      type="button"
      onClick={() => {
        // refresh family data before displaying the modal
        setFamily(JSON.parse(JSON.stringify(props.family || {})));
        setShowModal(true);
      }}
    >
      {family.familyName}&nbsp;({family.familyId})&nbsp;{props.readOnly ? iconUsersAlt : iconUsersEdit}
    </button>
  );

  let formActionButtons = props.readOnly || !isUpdated ?
    (
      <>
        <button
          className="bg-blue-400 hover:bg-blue-800 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={()=>{cancel(true);}}
          disabled={isSaving || isRefreshing}
        >
          Close
        </button>
      </>
    ) :
    (
      <>
        <button
          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={()=>{cancel(false);}}
          disabled={isSaving || isRefreshing}
        >
          Cancel
        </button>
        <button
          className={`${validationErrors.length > 0 ? "bg-red-400" : "bg-blue-400"} ${validationErrors.length > 0 ? "hover:bg-red-800" : "hover:bg-blue-800"} text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
          type="button"
          onClick={save}
          disabled={isSaving || isRefreshing || validationErrors.length > 0}
        >
          Save Changes
        </button>
      </>
    );

  let modalContent;
  if (showModal) {
    if (tokenRefresh.isRefreshing) {
      modalContent = (
        <>
          <div
            className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="px-3 py-4 flex justify-center">
                    Session expired, re-authenticating...
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      );
    } else {
      modalContent = (
        <>
          <div
            className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    {family.familyName} ({family.familyId})
                  </h3>
                  <div className="flex">
                    <button
                      className="p-0 ml-auto text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={refreshFamily}
                      disabled={isSaving || isRefreshing}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        {iconSync}
                      </span>
                    </button>
                    {spacer}
                    <button
                      className="px-5 ml-auto text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={()=>{cancel(true);}}
                      disabled={isSaving || isRefreshing}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        {iconTimes}
                      </span>
                    </button>
                  </div>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-y-auto h-2/4">
                  <p className="my-4 text-blueGray-500 text-sm mb-2 leading-relaxed">
                    {familyElements}
                  </p>
                  {familyFormFields}
                </div>
                {/*footer*/}
                { validationErrors.length > 0 ?
                  <div className="flex items-center justify-start p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <ul className="text-red-500 text-xs italic">
                      { validationErrors.map(str => {
                        return <li className="text-red-500 text-xs italic">{str}</li>;
                      }) }
                    </ul>
                  </div>
                 : null }
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  { formActionButtons }
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      );
    }
  }

  return (
    <>
      {family.familyId ? editFamilyButton : props.familyId}
      {modalContent}
    </>
  );
}