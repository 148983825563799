import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';
import { loadFromStorage } from '../storage/local';

import api from '../utils/api';
import hosts from '../utils/hosts';

function Confirmation (/*props*/) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, setHost ] = useContext(HostContext);
    const [ success, setSuccess ] = useState(false);
    const [ response, setResponse ] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const query = new URLSearchParams(location.search);

    let initialHost = hosts.determineInitialHost(host);

    useEffect(() => {
        hosts.hostUpdate({ location, params, initialHost, host, setHost, user, setUser, navigate });
        confirm();
    }, [ host ]); // eslint-disable-line react-hooks/exhaustive-deps

    let confirmationParams = {
        userId: params.userId,
        otp: query.get("token")
    };

    const __DEV__ = query.get("dev") === "true";
    if (__DEV__) {
        console.warn(`---DEV MODE---`);
    }

    const confirmPostRequest = async (values) => {
        // override from storage required for email-linked pages
        let intendedHost = loadFromStorage("host");
        if (__DEV__) {
            console.log(`confirming account with ${intendedHost.key} (mocked response)`);
            return {
                "success": true,
                "message": "Account confirmation succeeded."
            };
        }
        console.log(`confirming account with ${intendedHost.key}`);
        return await (
            await fetch(
                api.confirm(intendedHost.url),
                api.formatRequest({ method: "POST", body: values })
            )
        ).json();
    };

    const confirm = async() => {
        let json = await confirmPostRequest(confirmationParams);

        if (json.reason) {
            setResponse(json.reason);
        } else {
            setResponse(json.message);
            setSuccess(true);
        }
    };

    let message, successMessage;
    if (!response) {
        message = (
            <p>
                Confirming account registration...
            </p>
        );
    } else {
        message = (
            <p>
                {response}
            </p>
        );
    }
    if (success) {
        successMessage = "You can close your browser window now.";
    }

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
            {message}
            {successMessage}
        </div>
    );
}

export default Confirmation;