import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';
import { saveToStorage } from "../storage/local";
import Select from 'react-select';
import zxcvbn from 'zxcvbn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import api from '../utils/api';
import hosts from '../utils/hosts';

const showEye = <FontAwesomeIcon icon={faEye} />;
const hideEye = <FontAwesomeIcon icon={faEyeSlash} />;

const spacer = <>&nbsp;</>;
const VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Register (/*props*/) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, setHost ] = useContext(HostContext);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ showPassword, setShowPassword ] = useState(false);
    const [ response, setResponse ] = useState(null);
    const [ responseSuccess, setResponseSuccess ] = useState(null);
    const [ isSigningUp, setIsSigningUp ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const query = new URLSearchParams(location.search);

    // identify hosts
    let hostsCount = 0;
    let initialHost = host;
    for (let key in api.hosts) {
        // only count non-test hosts
        if (key.substr(0, 5) !== "test-") {
            initialHost = initialHost || api.hosts[key];
            hostsCount++;
        }
    }

    useEffect(() => {
        hosts.hostUpdate({ location, params, initialHost, host, setHost, user, setUser, navigate });
    }, [ host ]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateHost = selectedOption => {
        setHost(api.hosts[selectedOption.value]);
        console.log(`Option selected:`, selectedOption);
    };

    const __DEV__ = query.get("dev") === "true";
    if (__DEV__) {
        console.warn(`---DEV MODE---`);
    }

    let communitySelector;
    if (__DEV__ || hostsCount > 1) {
        let communityOptions = [];
        for (let key in api.hosts) {
            let host = api.hosts[key];
            if (__DEV__ || host[key].substr(0, 5) !== "test-") {
                communityOptions.push({
                    value: host.key,
                    label: host.name
                });
            }
        }
        communitySelector = <div className="flex items-center">
            <div className="w-min">
                Community:
            </div>
            <div className="w-full p-4">
                <Select
                    value={{
                        value: host.key,
                        label: host.name
                    }}
                    options={communityOptions}
                    onChange={updateHost}
                />
            </div>
        </div>;
    }

    const goToSignin = async () => {
        navigate("/");
    };

    const register = async () => {
        setIsSigningUp(true);
        try {
            let values = {
                name,
                email: email.trim(),
                password,
            };
            console.log(`signing up to ${host.name}`);
            let json = await (
                await fetch(
                    api.register(host.url),
                    api.formatRequest({ method: "POST", body: values })
                )
            ).json();
            if (json.reason) {
                setResponse(json.reason);
                setResponseSuccess(false);
                setIsSigningUp(false);
            } else {
                console.log('registration successful');

                saveToStorage("host", host);
                setResponse(json.message);
                setResponseSuccess(true);
                setIsSigningUp(false);
            }
        } catch (e) {
            console.log(e);
            toast.error("A network error occurred.");
            setIsSigningUp(false);
        }
    };

    let nameEntered = name.length > 2;

    let emailEntered = email.length > 0;
    let emailValidationWarning;
    if (email.length > 0 && !(VALID_EMAIL.test(email))) {
        emailValidationWarning = <p className="text-red-500 text-xs italic">Please enter a valid email address.</p>;
    }

    let passwordEntered = password.length > 0;
    let passwordValidationWarning;
    let passwordStrength;
    if (passwordEntered) {
        if (password.length < 8) {
            passwordValidationWarning = <p className="text-red-500 text-xs italic">Password must be at least 8 characters in length.</p>;
        } else {
            // show strength + message if insufficient
            let strength = zxcvbn(password).score;
            switch (strength) {
                case 2:
                    passwordStrength = <p className="text-pink-400 text-xs italic">Password strength: Weak</p>;
                    break;
                case 3:
                    passwordStrength = <p className="text-yellow-600 text-xs italic">Password strength: Risky</p>;
                    break;
                case 4:
                    passwordStrength = <p className="text-green-600 text-xs italic">Password strength: Strong</p>;
                    break;
                default:
                    passwordStrength = <p className="text-red-500 text-xs italic">Password strength: Weak</p>;
            }
            if (strength < 3) {
                passwordValidationWarning = <p className="text-red-500 text-xs italic">Password strength does not meet minimum safety requirements.</p>;
            }
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const tryAgain = async () => {
        setResponse(null);
        setResponseSuccess(null);
    };

    let signUpDisabled = isSigningUp || !nameEntered || !emailEntered || !passwordEntered || emailValidationWarning || passwordValidationWarning;

    const checkForSubmit = (e) => {
        if (!signUpDisabled) {
            if (e.key === 'Enter') {
                register();
            }
        }
    };

    let signUpOrResponse;
    if (!response) {
        signUpOrResponse = <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={register}
            disabled={signUpDisabled}
        >
            Sign Up
        </button>;
    } else {
        if (responseSuccess) {
            signUpOrResponse = <div className="flex items-center">
                <p className="">Success! {response}</p>
            </div>;
        } else {
            signUpOrResponse = <div className="flex items-center">
                <p className="text-red-500">{response}</p>
                {spacer}
                <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={tryAgain}
                >
                    Try Again
                </button>
            </div>;
        }
    }

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
            <div className="mb-4">
                <label className="block text-grey-800 text-sm font-bold mb-2" htmlFor="name">
                    Name
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-800"
                    id="name"
                    type="text"
                    placeholder="Full Name"
                    onChange={e => { setName(e.target.value); }}
                    onKeyDown={checkForSubmit}
                    value={name}
                ></input>
            </div>
            <div className="mb-4">
                <label className="block text-grey-800 text-sm font-bold mb-2" htmlFor="email">
                    Email Address
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-800"
                    id="email"
                    type="text"
                    placeholder="Email address"
                    onChange={e => { setEmail(e.target.value); }}
                    onKeyDown={checkForSubmit}
                    value={email}
                ></input>
                {emailValidationWarning}
            </div>
            <div className="mb-6">
                <label className="block text-grey-800 text-sm font-bold mb-2" htmlFor="password">
                    Password
                </label>
                <div className="relative w-full">
                    <div className="absolute right-0 flex items-center px-2 py-3">
                        <input className="hidden js-password-toggle"
                            id="toggle"
                            type="checkbox"
                            onClick={toggleShowPassword}
                        />
                        <label className="rounded px-2 text-sm text-black font-mono cursor-pointer js-password-label"
                            htmlFor="toggle"
                            title={showPassword ? "Hide password" : "Show password"}
                        >
                            {showPassword ? hideEye : showEye}
                        </label>
                    </div>
                    <input className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        onChange={e => { setPassword(e.target.value); }}
                        onKeyDown={checkForSubmit}
                        autoComplete="off"
                    />
                </div>
                {passwordStrength}
                {passwordValidationWarning}
            </div>
            <div className="flex items-center">
                {signUpOrResponse}
                {spacer}
                <button className="p-4 bg-blue-400 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={goToSignin}
                >
                    Sign In
                </button>
            </div>
            {communitySelector}
        </div>
    );
}

export default Register;