import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

import api from './api';
import fields from './fields';
import tokenRefresh from './tokenRefresh';

const MAX_ITEMS_PER_REQUEST = 50;
const MAX_PARALLEL_REQUESTS = 10;
const REQUEST_STATUS_PENDING = "pending";
const REQUEST_STATUS_SENT = "sent";
const REQUEST_STATUS_COMPLETE = "complete";

const self = {
    familyList: {},
    memberList: {},
    seenValues: {},
    isAuthenticating: false,
    isSyncing: false,
    isCancelled: false,
    // @ts-ignore - we want the empty functions if not provided
    promise: { resolve: ()=>{}, reject: ()=>{}},
    familiesStack: [],
    membersStack: [],
    requestsStack: { length: 0 },
    errorStack: [],
    totalMembersFound: 0,
    totalFamiliesFound: 0,
    totalRequestsCreated: 0,
    totalMembersProcessed: 0,
    totalFamiliesProcessed: 0,
    totalRequestsProcessed: 0,
    refreshToken: async () => {
        tokenRefresh.refresh({ host: self.host, user: self.user })
        .then((userSession) => {
            self.user = userSession;
            // propagate up
            self.setUser(userSession);
            console.log(`token refresh complete`);
        })
        .catch(err => {
            toast.error(err && err.message ? err.message : err);
            self.setUser({});
        })
        .finally(()=>{
            self.isAuthenticating = false;
        });
    },
    sync: ({
        host, // for api calls
        user, setUser, // for token renewal
        setFamilyList,
        setMemberList,
        setFamilyLookup,
        setProgressPercentage,
        setErrorStack
    }) => {
        return new Promise(async (resolve, reject) => {
            if (self.isSyncing) {
                return reject(new Error(`Member sync in progress`));
            }
            self.isSyncing = true;

            self.host = host;
            self.user = user;
            self.setUser = setUser;
            self.setFamilyList = setFamilyList;
            self.setMemberList = setMemberList;
            self.setFamilyLookup = setFamilyLookup;
            self.setProgressPercentage = setProgressPercentage;
            // @ts-ignore - we want the empty function if not provided
            self.setErrorStack = setErrorStack || (() => {});

            self.isAuthenticating = false;
            self.isCancelled = false;
            self.promise = { resolve, reject };

            self.familiesStack = [];
            self.membersStack = [];
            self.requestsStack = { length: 0 };
            self.errorStack = [];

            self.totalMembersFound = 0;
            self.totalFamiliesFound = 0;
            self.totalRequestsCreated = 0;
            self.totalMembersProcessed = 0;
            self.totalFamiliesProcessed = 0;
            self.totalRequestsProcessed = 0;

            // get families and members lists in parallel
            // create a request id and add it to the stack
            self.createListRequest({
                requestType: "listFamilies",
                setCompleted: (result) => {
                    if (result) {
                        for (let key in result.families) {
                            if (result.families.hasOwnProperty(key)) {
                                // check for existing record, create request if necessary
                                if (!self.familyList[key] ||
                                    self.familyList[key].updated < result.families[key]) {
                                    self.familyList[key] = result.families[key];
                                    self.familiesStack.push(key);
                                    self.totalFamiliesFound++;
                                }
                            }
                        }
                        // remove any deleted families
                        for (let key in self.familyList) {
                            if (!result.families.hasOwnProperty(key)) {
                                delete self.familyList[key];
                            }
                        }
                        self.setFamilyList(self.familyList);
                    }
                }
            });

            self.createListRequest({
                requestType: "listMembers",
                setCompleted: (result) => {
                    if (result) {
                        for (let key in result.members) {
                            if (result.members.hasOwnProperty(key)) {
                                // check for existing record, create request if necessary
                                if (!self.memberList[key] ||
                                    self.memberList[key].updated < result.members[key]) {
                                    self.memberList[key] = result.members[key];
                                    self.membersStack.push(key);
                                    self.totalMembersFound++;
                                }
                            }
                        }
                        // remove any deleted members
                        for (let key in self.memberList) {
                            if (!result.members.hasOwnProperty(key)) {
                                delete self.memberList[key];
                            }
                        }
                        self.setMemberList(self.memberList);
                    }
                }
            });

            // start processing loop after creating the first requests
            // to ensure the processing loop doesn't end before the
            // initial requests are made
            self.processInterval = setInterval(() => {
                self.processStacks();
            }, 100);

            toast(`Performing sync...`);
        });
    },
    createListRequest: ({
        requestType,
        setCompleted
    }) => {
        let requestId = uuid();
        self.requestsStack[requestId] = {
            status: REQUEST_STATUS_PENDING,
            url: requestType === "listFamilies" ?
                api.families(self.host.url) :
                api.members(self.host.url),
            method: "GET",
            requestType,
            setCompleted,
            result: null
        };
        self.totalRequestsCreated++;
        self.requestsStack.length++;
    },
    clearProcessInterval: () => {
        if (self.processInterval) {
            clearInterval(self.processInterval);
            self.processInterval = null;
        }
    },
    cancel: () => {
        self.isCancelled = true;
        self.clearProcessInterval();
        self.isSyncing = false;
        self.promise.reject(new Error("Sync aborted."));
    },
    complete: () => {
        self.isCancelled = false;
        self.clearProcessInterval();
        self.isSyncing = false;
        if (self.errorStack.length > 0) {
            toast.warn(`Sync completed with errors.`);
        } else {
            toast.success(`Sync complete.`);
        }
        self.setMemberList(self.memberList);
        self.setFamilyList(self.familyList);

        let familyLookup = {};
        for (let memberId in self.memberList) {
            let member = self.memberList[memberId];
            familyLookup[member.familyId] = familyLookup[member.familyId] || {};
            familyLookup[member.familyId][memberId] = {
                memberId,
                familyName: member.familyName,
                title: member.title,
                firstNames: member.firstNames,
                isDependant: member.isDependant
            };
        }
        self.setFamilyLookup(familyLookup);

        // convert self.seenValues objects to arrays and update the fields object
        for (let key in self.seenValues) {
            delete self.seenValues[key][''];
            let keyArray = Object.keys(self.seenValues[key]);
            fields.setSeenValues(key, keyArray);
        }

        self.promise.resolve({
            memberList: self.memberList,
            familyList: self.familyList,
            familyLookup
        });
    },
    fixMemberNames: (member) => {
        if (member.name) {
            if (!member.firstNames) {
                member.firstNames = member.name.substr(0, member.name.lastIndexOf(" "));
            }
            if (!member.familyName) {
                member.familyName = member.name.substr(member.name.lastIndexOf(" ") + 1);
            }
        } else {
            if (member.firstNames && member.familyName) {
                member.name = `${member.firstNames} ${member.familyName}`;
            }
        }

        return member;
    },
    processStacks: () => {
        if (self.isAuthenticating) {
            console.log(`processing on hold, authentication in progress...`);
            return;
        }
        console.log(`processing stacks`);

        // (re)send any pending requests
        // copy into an array of requestIds to prevent modifying the loop control
        let pendingRequests = [];
        let completedRequests = [];
        for (let requestId in self.requestsStack) {
            let request = self.requestsStack[requestId];
            switch (request.status) {
                case REQUEST_STATUS_PENDING:
                    pendingRequests.push(requestId);
                    break;
                case REQUEST_STATUS_COMPLETE:
                    completedRequests.push(requestId);
                    break;
                // no default
            }
        }
        for (let i in completedRequests) {
            let requestId = completedRequests[i];
            delete self.requestsStack[requestId];
            self.totalRequestsProcessed++;
            self.requestsStack.length--;
        }
        for (let i in pendingRequests) {
            let request = self.requestsStack[pendingRequests[i]];
            // just in case, ensure that the status hasn't somehow changed
            if (request.status === REQUEST_STATUS_PENDING) {
                // preemptively update the status
                request.status = REQUEST_STATUS_SENT;
                fetch(
                    request.url,
                    api.formatRequest({
                        method: request.method,
                        body: request.body,
                        authToken: self.user.authToken
                    })
                )
                .then(response => {
                    if(!response.ok) {
                        switch (response.status) {
                            case 401:
                                // attempt to refresh token
                                if (!self.isAuthenticating) {
                                    self.isAuthenticating = true;
                                    self.refreshToken();
                                }
                                // reset request status
                                request.status = REQUEST_STATUS_PENDING;

                                // wrap empty json in a promise
                                return new Promise((resolve) => {
                                    resolve({
                                        unauthorized: true
                                    });
                                });
                            case 403:
                                toast.error(`You are not authorized to perform this action.`);
                                self.cancel();
                                throw new Error(`403: user not authorized`);
                            default:
                                throw new Error(`${response.status}: ${response.body}`);
                        }
                    }
                    return response.json();
                })
                .then(json => {
                    if (json.success === false || json.reason || json.error) {
                        throw json.error;
                    }
                    if (json.unauthorized !== true) {
                        request.status = REQUEST_STATUS_COMPLETE;
                        request.setCompleted(json);
                    }
                })
                .catch(err => {
                    self.errorStack.push({ request, message: err.message });
                    self.setErrorStack(self.errorStack);
                    request.status = REQUEST_STATUS_COMPLETE;
                    request.setCompleted();
                });
            }
        }

        // create family requests
        while (self.familiesStack.length > 0
               && self.requestsStack.length < MAX_PARALLEL_REQUESTS) {
            // collect up to max families
            let postFamilies = {
                familyIds: []
            };
            while (self.familiesStack.length > 0
                && postFamilies.familyIds.length < MAX_ITEMS_PER_REQUEST) {
                postFamilies.familyIds.push(self.familiesStack.pop());
            }
            if (postFamilies.familyIds.length > 0) {
                // create a request and add it to the stack
                self.requestsStack[uuid()] = {
                    status: REQUEST_STATUS_PENDING,
                    url: api.familiesBatch(self.host.url),
                    method: "POST",
                    requestType: "families",
                    body: postFamilies,
                    setCompleted: (result) => {
                        for (let i in result) {
                            let family = result[i];
                            self.familyList[family.familyId] = family;
                            for (let key in family) {
                                switch (key) {
                                    case "familyId":
                                    case "updated":
                                        break;
                                    default:
                                        fields.addSeenField({
                                            name: key,
                                            value: family[key],
                                            source: "family"
                                        });
                                        self.addSeenValues(key, family[key]);
                                }
                            }
                            self.totalFamiliesProcessed++;
                        }
                        self.setFamilyList(self.familyList);
                    },
                    result: null
                };
                self.totalRequestsCreated++;
                self.requestsStack.length++;
            }
        }

        // create member requests
        while (self.membersStack.length > 0
               && self.requestsStack.length < MAX_PARALLEL_REQUESTS) {
            // collect up to max members
            let postMembers = {
                memberIds: []
            };
            while (self.membersStack.length > 0
                && postMembers.memberIds.length < MAX_ITEMS_PER_REQUEST) {
                postMembers.memberIds.push(self.membersStack.pop());
            }
            if (postMembers.memberIds.length > 0) {
                // create a request and add it to the stack
                self.requestsStack[uuid()] = {
                    status: REQUEST_STATUS_PENDING,
                    url: api.membersBatch(self.host.url),
                    method: "POST",
                    requestType: "members",
                    body: postMembers,
                    setCompleted: (result) => {
                        for (let i in result) {
                            let member = self.fixMemberNames(result[i]);
                            self.memberList[member.memberId] = member;
                            for (let key in member) {
                                switch (key) {
                                    case "memberId":
                                    case "updated":
                                        break;
                                    default:
                                        fields.addSeenField({
                                            name: key,
                                            value: member[key],
                                            source: "member"
                                        });
                                        self.addSeenValues(key, member[key]);
                                }
                            }
                            self.totalMembersProcessed++;
                        }
                        self.setMemberList(self.memberList);
                    },
                    result: null
                };
                self.totalRequestsCreated++;
                self.requestsStack.length++;
            }
        }

        console.log(`families processed: ${self.totalFamiliesProcessed}/${self.totalFamiliesFound} (${self.familiesStack.length} pending)`);
        console.log(`members processed: ${self.totalMembersProcessed}/${self.totalMembersFound} (${self.membersStack.length} pending)`);
        console.log(`requests processed: ${self.totalRequestsProcessed}/${self.totalRequestsCreated}`);

        self.setProgressPercentage(
            (self.totalFamiliesProcessed + self.totalMembersProcessed) /
            (self.totalFamiliesFound + self.totalMembersFound) * 100
        );

        if (self.totalFamiliesProcessed === self.totalFamiliesFound
            && self.totalMembersProcessed === self.totalMembersFound
            && self.totalRequestsProcessed === self.totalRequestsCreated) {
            self.complete();
        }
    },
    addSeenValues: (fieldName, seenValue) => {
        // add seen values to lookups of interest
        switch (fieldName) {
            case 'dataCodes':
            case 'hobbies':
            case 'title':
            case 'subsWrittenOff':
                // create an object for key uniqueness, on completion we'll
                // convert it to an array and update the fields object
                self.seenValues[fieldName] = self.seenValues[fieldName] || {};
                if (Array.isArray(seenValue)) {
                    let arrLength = seenValue.length;
                    for (let i = 0; i < arrLength; i++) {
                        // we just want to have a key
                        self.seenValues[fieldName][seenValue[i]] = true;
                    }
                }
                if (["string", "number", "boolean"].indexOf(typeof seenValue) > -1) {
                    self.seenValues[fieldName][seenValue] = true;
                }
                break;
            // no default
        }
    },
};

export default self;
