import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';

import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';

import api from '../utils/api';
import authorization from '../utils/authorization';
import hosts from '../utils/hosts';
import tableSorter from '../utils/tableSorter';
import tokenRefresh from '../utils/tokenRefresh';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const checkCircle = <FontAwesomeIcon icon={faCheckCircle} />;
const timesCircle = <FontAwesomeIcon icon={faTimesCircle} />;

const spacer = <>&nbsp;</>;

// we use this to ensure that parallel updates don't get missed
let baseUserList = {};
let baseUserRoles = {};

function UserManagement (/*props*/) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, setHost ] = useContext(HostContext);
    const [ userList, setUserList ] = useState();
    const [ isShowingInactiveUsers, setIsShowingInactiveUsers ] = useState(false);
    const [ isRefreshingUserList, setIsRefreshingUserList ] = useState();
    const [ roleList, setRoleList ] = useState();
    const [ isRefreshingRoleList, setIsRefreshingRoleList ] = useState();
    const [ statusList, setStatusList ] = useState();
    const [ isRefreshingStatusList, setIsRefreshingStatusList ] = useState();
    const [ userSelectedRoles, setUserSelectedRoles ] = useState({});
    const [ userUpdatesPending, setUserUpdatesPending ] = useState({});
    const [ isUserUpdating, setIsUserUpdating ] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        hosts.hostUpdate({ location, params, host, setHost, user, setUser, navigate });
        reloadScreen();
    }, [ host ]); // eslint-disable-line react-hooks/exhaustive-deps

    // check for user permissions
    const canEditUsers = authorization.hasRole(user, [authorization.ROLE.EDIT_USERS]);
    const canViewUsers = canEditUsers || authorization.hasRole(user, [authorization.ROLE.VIEW_USERS]);

    if (tokenRefresh.isRefreshing) {
        return (
            <div className="px-3 py-4 flex justify-center">
                Session expired, re-authenticating...
            </div>
        );
    }

    // token is not currently being refreshed
    const refreshAuthToken = async () => {
        if (!tokenRefresh.isRefreshing) {
            tokenRefresh.refresh({ host, user })
            .then((userSession) => {
                setUser(userSession);
                console.log(`refresh complete`);
            })
            .catch(err => {
                toast.error(err);
                setUser({});
            });
        }
    };

    if (user.authTokenExpiration - Date.now() <= 0) {
        refreshAuthToken();
    }

    const handleFetchErr = async (err) => {
        if (err.message !== "Invalid/expired authentication token") {
            console.error(err);
            toast.error(err);
        } else {
            refreshAuthToken();
        }
    };

    const getUser = (userId) => {
        return new Promise(async (resolve) => {
            try {
                console.log(`fetching userId ${userId}`);
                let json = await (
                    await fetch(
                        api.user(host.url, userId),
                        api.formatRequest({ method: "GET", authToken: user.authToken })
                    )
                ).json();
                if (json.reason) {
                    throw new Error(json.reason);
                }
                baseUserList[userId] = json;
                setUserList(baseUserList);
                baseUserRoles[userId] = json.roles;
                setUserSelectedRoles(baseUserRoles);
                resolve({
                    success: true,
                });
            } catch (err) {
                // baseUserList[userId] is currently the user's status
                baseUserList[userId] = `Error loading ${baseUserList[userId]} user: ${err.message}`;
                setUserList(baseUserList);
                delete baseUserRoles[userId];
                setUserSelectedRoles(baseUserRoles);
                resolve({
                    success: false,
                    error: err,
                });
            }
        });
    };

    const completeUserList = async() => {
        console.log(`front-loading users`);
        let promises = [];
        for (let userId in baseUserList) {
            promises.push(
                getUser(userId)
            );
        }
        Promise.all(promises)
        .catch(err => {
            console.log(err);
        })
        .then((/*resultArray*/) => {
            setUserList(baseUserList);
            setIsRefreshingUserList(false);
        });
    };

    const getUsersList = async () => {
        if (canViewUsers && !isRefreshingUserList) {
            setIsRefreshingUserList(true);
            setUserList({});
            try {
                let json = await (
                    await fetch(
                        api.users(host.url),
                        api.formatRequest({ method: "GET", authToken: user.authToken })
                    )
                ).json();
                if (json.reason) {
                    throw new Error(json.reason);
                }
                baseUserList = json;
                baseUserRoles = {};
                setUserList(baseUserList);
                completeUserList();
            } catch (err) {
                handleFetchErr(err);
            }
        }
    };

    const getRoleList = async () => {
        if (canViewUsers && !isRefreshingRoleList) {
            setIsRefreshingRoleList(true);
            try {
                let json = await (
                    await fetch(
                        api.roles(host.url),
                        api.formatRequest({ method: "GET", authToken: user.authToken })
                    )
                ).json();
                if (json.reason) {
                    throw new Error(json.reason);
                }
                json.sort();
                setRoleList(json);
            } catch (err) {
                handleFetchErr(err);
            } finally {
                setIsRefreshingRoleList(false);
            }
        }
    };

    const getStatusList = async () => {
        if (canViewUsers && !isRefreshingStatusList) {
            setIsRefreshingStatusList(true);
            try {
                let json = await (
                    await fetch(
                        api.statuses(host.url),
                        api.formatRequest({ method: "GET", authToken: user.authToken })
                    )
                ).json();
                if (json.reason) {
                    throw new Error(json.reason);
                }
                setStatusList(json);
            } catch (err) {
                handleFetchErr(err);
            } finally {
                setIsRefreshingStatusList(false);
            }
        }
    };

    const reloadScreen = () => {
        if (canViewUsers) {
            // initialize list of users
            getUsersList();

            // initialize list of roles
            getRoleList();

            // initialize list of statuses
            getStatusList();
        }
    };

    const updatePendingUpdates = async (userId, pendingUpdates) => {
        // if pending updates doesn't include any updates
        if (pendingUpdates[userId]) {
            if (!pendingUpdates[userId].status
                || pendingUpdates[userId].status === userList[userId].status) {
                if (!pendingUpdates[userId].roles) {
                    delete pendingUpdates[userId];
                } else {
                    let hasRolesAdded, hasRolesRemoved;
                    hasRolesAdded = pendingUpdates[userId].roles.add
                                    && pendingUpdates[userId].roles.add.length > 0;
                    hasRolesRemoved = pendingUpdates[userId].roles.remove
                                    && pendingUpdates[userId].roles.remove.length > 0;
                    if (!hasRolesAdded && !hasRolesRemoved) {
                        delete pendingUpdates[userId];
                    }
                }
            }
        }
        setUserUpdatesPending(pendingUpdates);
    };

    const setSelectedRole = async (userId, selectedRoles) => {
        selectedRoles = selectedRoles.map(obj => obj.value);

        // convert differences into the user's pending updates entry
        let pendingUpdates = {
            ...userUpdatesPending
        };

        let userRoles = userList[userId].roles;
        let rolesAdded = [];
        for (let i in selectedRoles) {
            let role = selectedRoles[i];
            if (userRoles.indexOf(role) < 0) {
                rolesAdded.push(role);
            }
        }
        let rolesRemoved = [];
        for (let i in userRoles) {
            let role = userRoles[i];
            if (selectedRoles.indexOf(role) < 0) {
                rolesRemoved.push(role);
            }
        }

        if (rolesAdded.length > 0 || rolesRemoved.length > 0) {
            pendingUpdates[userId] = pendingUpdates[userId] || {};
            pendingUpdates[userId].roles = pendingUpdates[userId].roles || {};
            if (rolesAdded.length > 0) {
                pendingUpdates[userId].roles.add = rolesAdded;
            } else {
                delete pendingUpdates[userId].roles.add;
            }
            if (rolesRemoved.length > 0) {
                pendingUpdates[userId].roles.remove = rolesRemoved;
            } else {
                delete pendingUpdates[userId].roles.remove;
            }
        } else {
            delete pendingUpdates[userId].roles;
        }
        updatePendingUpdates(userId, pendingUpdates);

        let selectedUserRoles = {};
        selectedUserRoles[userId] = selectedRoles;

        setUserSelectedRoles({
            ...userSelectedRoles,
            ...selectedUserRoles,
        });
    };

    const onStatusChange = async (event) => {
        let updateValue = event.target.value;
        let newValue = updateValue.substr(0, updateValue.indexOf(':'));
        let userId = updateValue.substr(updateValue.indexOf(':') + 1);

        let pendingUpdates = {
            ...userUpdatesPending
        };
        pendingUpdates[userId] = pendingUpdates[userId] || {};
        pendingUpdates[userId].status = newValue;
        updatePendingUpdates(userId, pendingUpdates);
    };

    const updateUser = async (userId) => {
        let newState = {};
        if (!isUserUpdating[userId] && userUpdatesPending[userId]) {
            newState[userId] = true;
            setIsUserUpdating({
                ...isUserUpdating,
                ...newState
            });

            try {
                console.log(`updateUser ${userId}`);
                console.log(userUpdatesPending[userId]);
                let json = await (
                    await fetch(
                        api.user(host.url, userId),
                        api.formatRequest({
                            method: "PUT",
                            body: userUpdatesPending[userId],
                            authToken: user.authToken
                        })
                    )
                ).json();
                if (json.reason) {
                    throw new Error(json.reason);
                }
                console.log(`response:`, json);
                let updatedUser = {};
                updatedUser[userId] = json;
                setUserList({
                    ...userList,
                    ...updatedUser
                });
                let selectedUserRoles = {};
                selectedUserRoles[userId] = json.roles;
                setUserSelectedRoles({
                    ...userSelectedRoles,
                    ...selectedUserRoles,
                });
                let pendingUpdates = {
                    ...userUpdatesPending
                };
                delete pendingUpdates[userId];
                setUserUpdatesPending(pendingUpdates);
            } catch (err) {
                handleFetchErr(err);
            } finally {
                newState[userId] = false;
                setIsUserUpdating({
                    ...isUserUpdating,
                    ...newState
                });
            }
        }
    };

    const revertUser = async (userId) => {
        let pendingUpdates = {
            ...userUpdatesPending
        };
        delete pendingUpdates[userId];
        setUserUpdatesPending(pendingUpdates);

        // reset selected roles
        let selectedUserRoles = {};
        selectedUserRoles[userId] = userList[userId].roles;
        setUserSelectedRoles({
            ...userSelectedRoles,
            ...selectedUserRoles,
        });
    };

    let orderedArray = [];
    for (let userId in userList) {
        if (typeof userList[userId] !== "object") {
            // entry is the user status or an error message
            orderedArray.push({
                userId,
                status: userList[userId],
            });
        } else {
            // user is a loaded object
            orderedArray.push(userList[userId]);
        }
    }
    // sort array
    // loading to the back, errors to the front, otherwise sort by name, then email
    orderedArray.sort(tableSorter.default('email', {'name': 'asc', 'email': 'asc' }));

    let userRows = [];
    for (let i in orderedArray) {
        let userEntry = orderedArray[i];
        if (isShowingInactiveUsers || ['rejected','deactivated','invalidated'].indexOf(userEntry.status) < 0)
        if (!userEntry.email) {
            // incomplete, details pending
            if (userEntry.status.toLowerCase().indexOf(`error`) > -1) {
                userRows.push(
                    <tr key={userEntry.userId} className="border-b hover:bg-orange-100 bg-red-300">
                        <td colSpan="4" className="p-3 px-5">{userEntry.status}</td>
                    </tr>
                );
            } else {
                userRows.push(
                    <tr key={userEntry.userId} className="border-b hover:bg-orange-100 bg-gray-100">
                        <td colSpan="4" className="p-3 px-5">Loading...</td>
                    </tr>
                );
            }
        } else {
            let roleOptions = [];
            let selectedRoleOptions = [];
            if (userSelectedRoles[userEntry.userId]) {
                for (let i in roleList || []) {
                    let roleOption = {
                        label: roleList[i],
                        value: roleList[i],
                    };
                    roleOptions.push(roleOption);
                    if (userSelectedRoles[userEntry.userId].indexOf(roleList[i]) > -1) {
                        selectedRoleOptions.push(roleOption);
                    }
                }
            }
            let statusOptions = [];
            for (let i in statusList || []) {
                statusOptions.push(
                    <option
                        key={`${statusList[i]}:${userEntry.userId}`}
                        value={`${statusList[i]}:${userEntry.userId}`}
                    >
                        {statusList[i]}
                    </option>
                );
            }
            let currentStatusValue = `${userEntry.status}:${userEntry.userId}`;
            if (userUpdatesPending[userEntry.userId] && userUpdatesPending[userEntry.userId].status) {
                currentStatusValue = `${userUpdatesPending[userEntry.userId].status}:${userEntry.userId}`;
            }
            userRows.push(
                <tr key={userEntry.userId} className="border-b hover:bg-orange-100 bg-gray-100">
                    <td className="p-3 px-5">{userEntry.name}</td>
                    <td className="p-3 px-5">{userEntry.email}</td>
                    <td className="p-3 px-5">
                        <MultiSelect
                            options={roleOptions}
                            value={selectedRoleOptions}
                            onChange={(selectedRoles) => { setSelectedRole(userEntry.userId, selectedRoles); }}
                            labelledBy="Select Role"
                            disabled={!canEditUsers}
                        />
                    </td>
                    <td className="p-3 px-5">
                        <select
                            value={currentStatusValue}
                            className="bg-transparent"
                            onChange={onStatusChange}
                            disabled={!canEditUsers}
                        >
                            {statusOptions}
                        </select>
                    </td>
                    <td className="p-3 px-5 flex justify-end">
                        <button
                            className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                            hidden={userUpdatesPending[userEntry.userId] == null}
                            disabled={isUserUpdating[userEntry.userId]}
                            onClick={() => {updateUser(userEntry.userId);}}
                        >
                            {checkCircle}
                        </button>
                        {spacer}
                        <button
                            className="p-4 bg-red-400 hover:bg-red-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                            hidden={userUpdatesPending[userEntry.userId] == null}
                            disabled={isUserUpdating[userEntry.userId]}
                            onClick={() => {revertUser(userEntry.userId);}}
                        >
                            {timesCircle}
                        </button>
                    </td>
                </tr>
            );
        }
    }

    const showHideInactiveUsers = (
        <div className="flex items-center">
            <div className="p-4 bg-blue-400 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">
                <label className="flex items-baseline">
                    <input className="form-checkbox"
                        type="checkbox"
                        defaultChecked={isShowingInactiveUsers}
                        onChange={(e) => {
                            setIsShowingInactiveUsers(e.target.checked);
                        }}
                    />
                    <span className="ml-2">Show Blocked Users</span>
                </label>
            </div>
        </div>
    );

    if (canViewUsers) {
        if (!userList) {
            if (isRefreshingUserList) {
                return (
                    <>
                        Loading...
                    </>
                );
            } else {
                return (
                    <div className="flex items-center">
                        <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                            type="button"
                            onClick={reloadScreen}
                        >
                            Refresh
                        </button>
                    </div>
                );
            }
        }
        return (
            <>
                <div className="flex justify-center">
                    {showHideInactiveUsers}
                </div>
                <div className="flex justify-center">
                    <div className="w-full px-3 py-4 flex justify-center">
                        <table className="w-full text-md bg-white shadow-md rounded mb-4">
                            <tbody>
                                <tr className="border-b">
                                    <th className="text-left p-3 px-5">Name</th>
                                    <th className="text-left p-3 px-5">Email</th>
                                    <th className="text-left p-3 px-5">Roles</th>
                                    <th className="text-left p-3 px-5">Status</th>
                                    <th></th>
                                </tr>
                                {userRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                Access Denied: you do not have the required authorization to view this page.
            </>
        );
    }
}

export default UserManagement;