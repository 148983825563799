
const self = {
    camelToSentenceCase: (name) => {
        /*
            insert a space before capital letters or numbers
            ([A-Z]|\d+) a capital letter or a number
        */
        return name.charAt(0).toUpperCase() + name.replace(/([A-Z]|\d+)/g,' $1').slice(1);
    },
    sentenceToCamelCase: (sentence) => {
        // replace periods, parentheses, slashes, hyphens with spaces
        sentence = sentence.replace(/(\.|\(|\)|\\|\/|-)/g, ' ');
        // remove apostrophes
        sentence = sentence.replace(/'/g, '');
        // if it ends with a question mark, insert "is" at the front
        if (sentence.charAt(sentence.length - 1) === '?') {
            sentence = "is " + sentence.substr(0, sentence.length-1);
        }
        // split on spaces and map first character to uppercase
        sentence = sentence.split(" ")
                    .map(word => {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    });
        // join
        sentence = sentence.join("");
        // lowercase first char
        return sentence.charAt(0).toLowerCase() + sentence.slice(1);
    },
    nameLookup: {
        "any": {
            "temple-israel-za-cpt": "Any Fields",
            "test-za": "-Any Fields-",
        },
        "birthdate": "Date of Birth (DD/MM/YYYY)",
        "jewishBday": "Hebrew Date of Birth",
        "familyId": "Family ID",
        "name": "Full Name",
        "dataCodes": "Data Codes / Tags",
    },
    seenValues: {},
    getSeenValues: (fieldName) => {
        return self.seenValues[fieldName] || [];
    },
    sourceLookup: {},
    typeLookup: {
        "any": "string",
        "birthdate": "string",
        "familyId": "number",
        "name": "string",
    },
    getName: ({host, field}) => {
        if (!self.nameLookup[field]) {
            return self.camelToSentenceCase(field);
        }
        return (typeof self.nameLookup[field] === "string") ?
            self.nameLookup[field]
            : self.nameLookup[field][host.key];
    },
    getSourceFields: (source) => {
        // source = family / member
        return self.sourceLookup[source];
    },
    getType: (field) => {
        // return null if field not found
        return self.typeLookup[field];
    },
    addSeenField: ({ name, value, source }) => {
        // add field name to seen fields
        if (!self.nameLookup[name]) {
            self.nameLookup[name] = self.camelToSentenceCase(name);
        }
        // update the type lookup if the value is not null
        if (value && !self.typeLookup[name]) {
            self.typeLookup[name] = typeof(value);
        }
        // update the source lookup
        if (source) {
            self.sourceLookup[source] = self.sourceLookup[source] || [];
            if (self.sourceLookup[source].indexOf(name) < 0) {
                self.sourceLookup[source].push(name);
            }
        }
    },
    addSeenValue: (fieldName, seenValue) => {
        self.seenValues[fieldName] = self.seenValues[fieldName] || [];
        if (Array.isArray(seenValue)) {
            let arrLength = seenValue.length;
            for (let i = 0; i < arrLength; i++) {
                if (self.seenValues[fieldName].indexOf(seenValue[i]) < 0) {
                    self.seenValues[fieldName].push(seenValue[i]);
                }
            }
        }
        if (["string", "number", "boolean"].indexOf(typeof seenValue) > -1) {
            if (self.seenValues[fieldName].indexOf(seenValue) < 0) {
                self.seenValues[fieldName].push(seenValue);
            }
        }
    },
    setSeenValues: (fieldName, seenValues) => {
        self.seenValues[fieldName] = seenValues;
    },
};

export default self;
