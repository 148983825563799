import LineNavigator from 'line-navigator';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

import api from '../utils/api';
import fields from './fields';
import tokenRefresh from '../utils/tokenRefresh';

const MAX_ITEMS_PER_REQUEST = 50;
const MAX_PARALLEL_REQUESTS = 10;
const REQUEST_STATUS_PENDING = "pending";
const REQUEST_STATUS_SENT = "sent";
const REQUEST_STATUS_COMPLETE = "complete";

// singleton, only one import can be performed at a time
const self = {
    requiredHeaders: [
        "familyId",
        "familyName1",
        "familyName2",
        "firstName1",
        "firstName2",
        "birthdate1",
        "birthdate2"
    ],
    isAuthenticating: false,
    refreshToken: async () => {
        tokenRefresh.refresh({ host: self.host, user: self.user })
        .then((userSession) => {
            self.user = userSession;
            // propagate up
            self.setUser(userSession);
            console.log(`token refresh complete`);
        })
        .catch(err => {
            toast.error(err && err.message ? err.message : err);
            self.setUser({});
        })
        .finally(()=>{
            self.isAuthenticating = false;
        });
    },
    isRunning: false,
    isCancelled: false,
    setFile: (file) => {
        return new Promise((resolve, reject) => {
            if (self.isRunning) {
                return reject(new Error(`import in progress`));
            }
            // clone file to enable modification / re-read
            let data = new FormData();
            data.append("file", file, file.name);
            self.file = data.get("file");
            self.navigator = new LineNavigator(self.file);
            // promisify readLines method
            self.navigator.readLinesPromise = (indexToStartWith, numberOfLines) => new Promise((resolve, reject) => {
                self.navigator.readLines(indexToStartWith, numberOfLines, function (err, index, lines, isEof, progress) {
                    if (err) {
                        return reject(err);
                    }
                    resolve({index, lines, isEof, progress});
                });
            });
            resolve();
        });
    },
    getHeaders: ({ setErrorStack }) => {
        return new Promise(async (resolve, reject) => {
            if (self.isRunning) {
                return reject(new Error(`import in progress`));
            }

            self.setErrorStack = setErrorStack;
            self.errorStack = [];

            // declare navigator variables for error reporting
            let line;
            try {
                // eslint-disable-next-line no-unused-vars
                let {/*index,*/ lines, isEof/*, progress*/} = await self.navigator.readLinesPromise(0, 1);

                if (isEof) {
                    return reject(new Error("cannot read headers from empty file"));
                }
                // replace separators with pipes and split on those
                line = lines[0];
                let columns = self.tokenize(line);
                let result = [];
                for (let c in columns) {
                    let column = columns[c].trim();
                    switch (column) {
                        case "Family number":
                            result.push("familyId");
                            break;
                        case "Last name, first name":
                            result.push("lastAndFirstNames");
                            break;
                        case "Birthday (1)":
                            result.push("birthdate1");
                            break;
                        case "Birthday (2)":
                            result.push("birthdate2");
                            break;
                        case "Family e-mail":
                            // fallback if no email address available
                            result.push("emailFamily");
                            break;
                        case "E-mail (1)":
                            result.push("email1");
                            break;
                        case "E-mail (2)":
                            result.push("email2");
                            break;
                        case "Cell phone (1)":
                            result.push("mobile1");
                            break;
                        case "Cell phone (2)":
                            result.push("mobile2");
                            break;
                        case "All Yahrzeits for family":
                            result.push("memorials");
                            break;
                        default:
                            console.log(`WARNING: unrecognized column header ${column} (length ${column.length})`);
                            let cleanedName = fields.sentenceToCamelCase(column);
                            // check for duplicate field names and fix if necessary
                            let duplicateIndex = result.indexOf(cleanedName);
                            if (duplicateIndex > -1
                                && column.length > 0) {
                                let previousEntry = result[duplicateIndex];
                                result[duplicateIndex] = `${previousEntry}1`;
                                console.log(`${previousEntry} => ${result[duplicateIndex]}`);
                                result.push(`${cleanedName}2`);
                            } else {
                                result.push(cleanedName);
                            }
                            break;
                    }
                    //console.log(`add header: ${result[result.length - 1]}`);
                }

                // zip the columns and result array to an array of objects
                resolve(result.map((element, index) => {
                    return {
                        selected: true,
                        original: columns[index],
                        fixed: element,
                        custom: '',
                    };
                }));
            } catch (err) {
                self.errorStack.push({
                    rowNumber: 0,
                    line,
                    message: err
                });
                self.setErrorStack(self.errorStack);
                reject(err);
            }
        });
    },
    setHeaders: (headers) => {
        self.headers = headers;
    },
    getSampleData: ({ headers/*, setErrorStack*/ }) => {
        return new Promise(async(resolve, reject) => {
            if (self.isRunning) {
                return reject(new Error(`import in progress`));
            }
            // we need to know which column indexes are missing data.
            // for each row until all data found (or eof), loop through
            // missing headers, if sample data found add to result and
            // cross off column index
            let indexes = [];
            for (let i = 0; i < headers.length; i++) {
                indexes.push(i);
            }
            let result = [];
            let rowNumber = 1;
            // declare navigator variables for error reporting
            let line;
            try {
                while (indexes.length > 0) {
                    // eslint-disable-next-line no-unused-vars
                    let {/*index,*/ lines, isEof/*, progress*/} = await self.navigator.readLinesPromise(rowNumber++, 1);
                    if (isEof) {
                        console.log(`sample data: end of file found`);
                        return resolve(result);
                    }
                    line = lines[0];
                    let row = self.tokenize(line);
                    // we only want to try to access missing data
                    let missingIndexes = [];
                    for (let i in indexes) {
                        let index = indexes[i];
                        if (row[index] && row[index].length > 0) {
                            console.log(`sample data found for ${headers[index].fixed} (${index})`);
                            result[index] = row[index];
                        } else {
                            missingIndexes.push(index);
                        }
                    }
                    indexes = missingIndexes;
                    //console.log(`missing indexes: `, indexes);
                }
            } catch (err) {
                self.errorStack.push({
                    rowNumber,
                    line,
                    message: err
                });
                self.setErrorStack(self.errorStack);
                return reject(err);
            }
            resolve(result);
        });
    },
    import: ({
        host, // for api calls
        user, setUser, // for token renewal
        setProgressPercentage,
        setErrorStack }) => {
        return new Promise(async (resolve, reject) => {
            if (self.isRunning) {
                return reject(new Error(`Import in progress`));
            }
            if (!self.headers) {
                return reject(new Error(`Headers not set`));
            }
            self.isRunning = true;

            self.host = host;
            self.user = user;
            self.setUser = setUser;
            self.setProgressPercentage = setProgressPercentage;
            self.setErrorStack = setErrorStack;

            self.isAuthenticating = false;
            self.isCancelled = false;
            self.promise = { resolve, reject };

            self.familiesStack = [];
            self.membersStack = [];
            self.requestsStack = { length: 0 };
            self.errorStack = [];

            self.totalLinesFound = 0;
            self.totalMembersFound = 0;
            self.totalFamiliesFound = 0;
            self.totalRequestsCreated = 0;
            self.totalLinesProcessed = 0;
            self.totalMembersProcessed = 0;
            self.totalFamiliesProcessed = 0;
            self.totalRequestsProcessed = 0;

            // start processing loop
            self.processInterval = setInterval(() => {
                self.processStacks();
            }, 100);

            toast(`Performing import...`);

            // declare navigator variables for error reporting
            let rowNumber = 1;
            let line;
            try
            {
                let fileReadComplete = false;
                while (!fileReadComplete) {
                    // eslint-disable-next-line no-unused-vars
                    let {index, lines, isEof/*, progress*/} = await self.navigator.readLinesPromise(rowNumber++, 1);
                    if (isEof) {
                        fileReadComplete = true;
                        toast(`File read complete.`);
                    } else {
                        for (let l in lines) {
                            line = lines[l];
                            self.totalLinesFound++;
                            self.processLine(Number(index) + Number(l), line);
                        }
                    }
                }
            } catch (err) {
                self.errorStack.push({
                    rowNumber,
                    line,
                    message: err
                });
                self.setErrorStack(self.errorStack);
                toast.error(err.message);
                self.cancel();
            }
        });
    },
    clearProcessInterval: () => {
        if (self.processInterval) {
            clearInterval(self.processInterval);
            self.processInterval = null;
        }
    },
    cancel: () => {
        self.isCancelled = true;
        self.clearProcessInterval();
        self.isRunning = false;
        self.promise.reject(new Error("Import aborted."));
    },
    complete: () => {
        self.isCancelled = false;
        self.clearProcessInterval();
        self.isRunning = false;
        if (self.errorStack.length > 0) {
            toast.warn(`Import completed with errors.`);
        } else {
            toast.success(`Import complete.`);
        }
        self.promise.resolve();
    },
    processStacks: () => {
        if (self.isAuthenticating) {
            console.log(`processing on hold, authentication in progress...`);
            return;
        }
        console.log(`processing stacks`);

        // (re)send any pending requests
        // copy into an array of requestIds to prevent modifying the loop control
        let pendingRequests = [];
        let completedRequests = [];
        for (let requestId in self.requestsStack) {
            let request = self.requestsStack[requestId];
            switch (request.status) {
                case REQUEST_STATUS_PENDING:
                    pendingRequests.push(requestId);
                    break;
                case REQUEST_STATUS_COMPLETE:
                    completedRequests.push(requestId);
                    break;
                // no default
            }
        }
        for (let i in completedRequests) {
            let requestId = completedRequests[i];
            delete self.requestsStack[requestId];
            self.totalRequestsProcessed++;
            self.requestsStack.length--;
        }
        for (let i in pendingRequests) {
            let request = self.requestsStack[pendingRequests[i]];
            // just in case, ensure that the status hasn't somehow changed
            if (request.status === REQUEST_STATUS_PENDING) {
                // preemptively update the status
                request.status = REQUEST_STATUS_SENT;
                fetch(
                    request.url,
                    api.formatRequest({
                        method: "POST",
                        body: request.body,
                        authToken: self.user.authToken
                    })
                )
                .then(response => {
                    if(!response.ok) {
                        switch (response.status) {
                            case 401:
                                // attempt to refresh token
                                if (!self.isAuthenticating) {
                                    self.isAuthenticating = true;
                                    self.refreshToken();
                                }
                                // reset request status
                                request.status = REQUEST_STATUS_PENDING;

                                // wrap empty json in a promise
                                return new Promise((resolve) => {
                                    resolve({
                                        unauthorized: true
                                    });
                                });
                            case 403:
                                toast.error(`You are not authorized to perform this action.`);
                                self.cancel();
                                throw new Error(`403: user not authorized`);
                            default:
                                throw new Error(`${response.status}: ${response.body}`);
                        }
                    }
                    return response.json();
                })
                .then(json => {
                    if (json.success === false || json.reason || json.error) {
                        throw json.error;
                    }
                    if (json.unauthorized !== true) {
                        request.status = REQUEST_STATUS_COMPLETE;
                        request.setCompleted(json);
                    }
                })
                .catch(err => {
                    self.errorStack.push({ request, message: err.message });
                    self.setErrorStack(self.errorStack);
                    request.status = REQUEST_STATUS_COMPLETE;
                    request.setCompleted();
                });
            }
        }

        // create a family request
        if (self.requestsStack.length < MAX_PARALLEL_REQUESTS) {
            // collect up to max families
            let postFamilies = [];
            while (self.familiesStack.length > 0
                && postFamilies.length < MAX_ITEMS_PER_REQUEST) {
                postFamilies.push(self.familiesStack.pop());
            }
            if (postFamilies.length > 0) {
                // create a request and add it to the stack
                self.requestsStack[uuid()] = {
                    status: REQUEST_STATUS_PENDING,
                    url: api.families(self.host.url),
                    requestType: "families",
                    body: postFamilies,
                    setCompleted: () => {
                        self.totalFamiliesProcessed += postFamilies.length;
                    },
                    result: null
                };
                self.totalRequestsCreated++;
                self.requestsStack.length++;
            }
        }

        // create a member request
        if (self.requestsStack.length < MAX_PARALLEL_REQUESTS) {
            // collect up to max members
            let postMembers = [];
            while (self.membersStack.length > 0
                && postMembers.length < MAX_ITEMS_PER_REQUEST) {
                postMembers.push(self.membersStack.pop());
            }
            if (postMembers.length > 0) {
                // create a request and add it to the stack
                self.requestsStack[uuid()] = {
                    status: REQUEST_STATUS_PENDING,
                    url: api.members(self.host.url),
                    requestType: "members",
                    body: postMembers,
                    setCompleted: () => {
                        self.totalMembersProcessed += postMembers.length;
                    },
                    result: null
                };
                self.totalRequestsCreated++;
                self.requestsStack.length++;
            }
        }

        console.log(`lines processed: ${self.totalLinesProcessed}/${self.totalLinesFound}`);
        console.log(`families processed:
                    ${self.totalFamiliesProcessed}/${self.totalFamiliesFound}
                    (${self.familiesStack.length} pending)`);
        console.log(`members processed:
                    ${self.totalMembersProcessed}/${self.totalMembersFound}
                    (${self.membersStack.length} pending)`);
        console.log(`requests processed: ${self.totalRequestsProcessed}/${self.totalRequestsCreated}`);

        self.setProgressPercentage(
            (self.totalFamiliesProcessed + self.totalMembersProcessed) /
            (self.totalFamiliesFound + self.totalMembersFound) * 100
        );

        if (self.totalLinesProcessed === self.totalLinesFound
            && self.totalFamiliesProcessed === self.totalFamiliesFound
            && self.totalMembersProcessed === self.totalMembersFound
            && self.totalRequestsProcessed === self.totalRequestsCreated) {
            self.complete();
        }
    },
    processLine: (index, line) => {
        console.log(`processing line ${index}`);
        if (!line || line.length === 0) {
            self.errorStack.push({
                index,
                line,
                message: `Cannot process empty line.`
            });
            self.setErrorStack(self.errorStack);
            self.totalLinesProcessed++;
            return;
        }

        try {
            // create row object { headerName: columnValue}
            let rowValues = self.tokenize(line);
            let row = {};
            for (let h in self.headers) {
                row[self.headers[h]] = rowValues[h];
            }

            // handled fields
            let handledFields = [];

            let familyId = row["familyId"];
            handledFields.push("familyId");

            // if no familyName1 we have a special row, add to error stack
            if (!row.familyName1 || row.familyName1.length === 0) {
                self.errorStack.push({
                    index,
                    line,
                    message: `Special entry, cannot process.`
                });
                self.setErrorStack(self.errorStack);
                self.totalLinesProcessed++;
                return;
            }

            handledFields.push(
                "allHebrewNames",
                "firstName1",
                "firstName2",
                "familyName1",
                "familyName2",
                "sex1",
                "sex2",
                "converted1",
                "converted2",
                "subsWrittenOff",
                "isGiftAid",
                "isReciprocateMM",
                "aliyah1",
                "aliyah2",
                "title1",
                "title2",
                "jewishBdayCivil1",
                "jewishBdayCivil2",
            );

            // if we don't have a partner then all partner's data must be merged in to main member,
            //      so take note here so we don't have to duplicate the work
            let hasPartner = (row.familyName2 && row.familyName2.length > 0);
            let family = {
                familyId,
                familyName: row.familyName1,
                addresses: [],
                contactDetails: [],
            };
            if (self.headers.indexOf("subsWrittenOff") > -1) {
                family.subsWrittenOff = self.splitAndTrim(row.subsWrittenOff);
            }
            if (self.headers.indexOf("isGiftAid") > -1) {
                family.isGiftAid = self.fixBoolean(row.isGiftAid);
            }
            if (self.headers.indexOf("isReciprocateMM") > -1) {
                family.isReciprocateMM = self.fixBoolean(row.isReciprocateMM);
            }

            let allHebrewNames = self.splitAndTrim(row["allHebrewNames"]);

            let members = [
                {
                    familyId,
                    name: `${row.firstName1} ${row.familyName1}`,
                    familyName: row.familyName1,
                    firstNames: row.firstName1,
                    isDependant: false,
                    contactDetails: [],
                    hebrewName: allHebrewNames[0],
                    gender: self.fixGender(row.sex1 || row.gender1),
                    title: self.splitAndTrim(row.title1),
                }
            ];
            let convertedCodes = [];
            if (self.headers.indexOf("converted1") > -1) {
                let converted = (row.converted1 || "")
                .toString()
                .toLowerCase();
                members[0].converted = converted.length > 0 && converted !== "n" && converted !== "no";
                // converted might be notes / have notes attached that should be moved to data codes
                if (members[0].converted
                    && converted.indexOf(" ") > -1
                    && converted.indexOf(" ") < converted.length - 1) {
                    convertedCodes[0] = `CONVERTED ${row.converted1}`;
                }
            }
            if (self.headers.indexOf("aliyah1") > -1) {
                members[0].aliyah = self.fixBoolean(row.aliyah1);
            }

            if (hasPartner) {
                members.push({
                    familyId,
                    name: `${row.firstName2} ${row.familyName2}`,
                    familyName: row.familyName2,
                    firstNames: row.firstName2,
                    isDependant: false,
                    contactDetails: [],
                    hebrewName: allHebrewNames[1],
                    gender: self.fixGender(row.sex2 || row.gender2),
                    converted: self.fixBoolean(row.converted2),
                    title: self.splitAndTrim(row.title2),
                });
                if (self.headers.indexOf("converted2") > -1) {
                    let converted = (row.converted2 || "")
                    .toString()
                    .toLowerCase();
                    members[1].converted = converted.length > 0 && converted !== "n" && converted !== "no";
                    // converted might be notes / have notes attached that should be moved to data codes
                    if (members[1].converted
                        && converted.indexOf(" ") > -1
                        && converted.indexOf(" ") < converted.length - 1) {
                        convertedCodes[1] = `CONVERTED ${row.converted2}`;
                    }
                }
                if (self.headers.indexOf("aliyah2") > -1) {
                    members[1].aliyah = self.fixBoolean(row.aliyah2);
                }
            }

            let noChildren = 0;
            let hasChildren = (
                row.noChildrenInFamily
                && row.noChildrenInFamily.length > 0
                && Number(row.noChildrenInFamily) > 0);
            let children = [];
            if (hasChildren) {
                noChildren = Number(row.noChildrenInFamily);
                handledFields.push("noChildrenInFamily");
                let names = self.splitAndTrim(row.allChildrenInFamily);
                handledFields.push("allChildrenInFamily");
                let hebrewNames = self.splitAndTrim(row.allChildrenHebrewNames);
                handledFields.push("allChildrenHebrewNames");
                let childrenInSchool = self.splitAndTrim(row.allChildrenInSchool);
                handledFields.push("allChildrenInSchool");
                let childrenInReligiousSchool = self.splitAndTrim(row.noChildrenReligSchl);
                handledFields.push("noChildrenReligSchl");
                for (let i = 0; i < noChildren; i++) {
                    let namesArray = names[i].split(" ");
                    let familyName = namesArray[namesArray.length - 1];
                    let firstNames = namesArray.length > 1 ? namesArray.slice(0, -1).join(" ") : namesArray[0];
                    children.push({
                        familyId,
                        isDependant: true,
                        name: names[i],
                        firstNames,
                        familyName,
                        hebrewName: hebrewNames[i],
                        inSchool: childrenInSchool.indexOf(names[i]) > -1,
                        inReligiousSchool: childrenInReligiousSchool.indexOf(names[i]) > -1
                    });
                }
            }

            let addressFields = {
                "": {
                    "name": "main",
                    "unit": "streetAddress",
                    "street": "secondAddressLine",
                    "city": "cityTown",
                    "cityStateZip": "cityStateZip",
                    "state": "stateProvinceCounty",
                    "postCode": "zipPostalCode",
                    "country": "country",
                },
                "Active": {
                    "name": "active",
                    "unit": "streetAddressActive",
                    "street": "secondAddrLineActive",
                    "city": "cityTownActive",
                    "cityStateZip": "cityStateZipActive",
                    "state": "statePrvCountyActive",
                    "postCode": "zipPostalCodeActive",
                    "country": "countryActive",
                },
                "Sec": {
                    "name": "alternate",
                    "unit": "streetAddressSec",
                    "street": "secondAddrLineSec",
                    "city": "cityTownSec",
                    "cityStateZip": "cityStateZipSec",
                    "state": "statePrvCountySec",
                    "postCode": "zipPostalCodeSec",
                    "country": "countrySec",
                }
            };

            for (let suffix in addressFields) {
                let hasAnyValue = false;
                let fields = addressFields[suffix];
                for (let f in fields) {
                    if (fields[f] !== "name") {
                        hasAnyValue = hasAnyValue || self.hasStringValue(row[fields[f]]);
                        handledFields.push(fields[f]);
                    }
                }
                if (hasAnyValue) {
                    family.addresses.push({
                        detail: {
                            street: row[fields.street],
                            unit: row[fields.unit],
                            city: row[fields.city],
                            state: row[fields.state],
                            postCode: row[fields.postCode],
                            country: row[fields.country]
                        },
                        name: fields.name,
                    });
                }
            }

            // contact fields
            let phoneFields = {
                "family": {
                    "homePhone": {
                        contactType: "telephone",
                        name: "home"
                    },
                    "homePhoneActive": {
                        contactType: "telephone",
                        name: "active"
                    },
                    "homePhoneSec": {
                        contactType: "telephone",
                        name: "secondary"
                    },
                    "fax" : {
                        contactType: "fax",
                        name: "fax"
                    }
                },
                "member": {
                    "mobile": "mobile",
                    "workPhone": "work",
                    "pager": "pager"
                }
            };
            let seenNumbers = [];
            for (let mpf in phoneFields.member) {
                for (let i = 0; i < 2; i++) {
                    let header = `${mpf}${i+1}`;
                    let field = mpf;
                    handledFields.push(header);
                    let contactType = (field === "mobile") ? field : "telephone";
                    let cleanedValue = self.cleanPhone(phoneFields.member[mpf], contactType, row[header]);
                    if (cleanedValue && seenNumbers.indexOf(cleanedValue.detail) < 0) {
                        seenNumbers.push(cleanedValue.detail);
                        if (hasPartner) {
                            members[i].contactDetails.push(cleanedValue);
                        } else {
                            // merge into main member
                            members[i].contactDetails.push(cleanedValue);
                        }
                    }
                }
            }
            for (let header in phoneFields.family) {
                let obj = phoneFields.family[header];
                handledFields.push(header);
                let cleanedValue = self.cleanPhone(obj.name, obj.contactType, row[header]);
                if (cleanedValue && seenNumbers.indexOf(cleanedValue.detail) < 0) {
                    seenNumbers.push(cleanedValue.detail);
                    family.contactDetails.push(cleanedValue);
                }
            }

            let emailFields = {
                "family": {
                    "emailFamily": {
                        contactType: "email",
                        name: "family"
                    }
                },
                "member": [
                    "email"
                ]
            };
            for (let mef in emailFields.member) {
                for (let i = 0; i < 2; i++) {
                    let header = `${emailFields.member[mef]}${i+1}`;
                    handledFields.push(header);
                    let emailAddress = row[header];
                    if (emailAddress
                        && emailAddress.length > 0) {
                        members[hasPartner ? i : 0].contactDetails.push({
                            name: "main",
                            contactType: "email",
                            detail: emailAddress
                        });
                    }
                }
            }
            for (let header in emailFields.family) {
                handledFields.push(header);
                let emailAddress = row[header];
                if (emailAddress
                    && emailAddress.length > 0) {
                    family.contactDetails.push({
                        name: "family",
                        contactType: "email",
                        detail: emailAddress
                    });
                }
            }

            for (let i = 0; i < 4; i++) {
                let header = `browseAssessments${i+1}`;
                if (self.headers.indexOf(header) > -1) {
                    handledFields.push(header);
                    family.browseAssessments = family.browseAssessments || [];
                    family.browseAssessments[i] = row[header];
                }
            }

            // unhandled fields
            for (let h in self.headers) {
                let header = self.headers[h];
                // ignore null headers and handled headers
                if (header && handledFields.indexOf(header) < 0) {
                    let lastHeaderChar = header.charAt(header.length - 1);
                    if (lastHeaderChar === "1"
                        || lastHeaderChar === "2") {
                        // removed last char
                        let fixedHeader = header.substr(0, header.length-1);

                        let memberIndex = hasPartner ? Number(lastHeaderChar)-1 : 0;

                        switch (fixedHeader) {
                            case "memberDataCodes":
                                if (hasPartner) {
                                    members[memberIndex].dataCodes = self.splitAndTrim(row[header]);
                                    if (convertedCodes[memberIndex]) {
                                        members[memberIndex].dataCodes.push(convertedCodes[memberIndex]);
                                    }
                                } else {
                                    members[memberIndex].dataCodes = members[memberIndex].dataCodes || [];
                                    members[memberIndex].dataCodes = members[memberIndex].dataCodes.concat(
                                        self.splitAndTrim(row[header])
                                    );
                                    if (convertedCodes[memberIndex]) {
                                        members[memberIndex].dataCodes.push(convertedCodes[memberIndex]);
                                    }
                                }
                                break;
                            case "hobbies":
                                if (hasPartner) {
                                    members[memberIndex][fixedHeader] = self.splitAndTrim(row[header]);
                                } else {
                                    members[memberIndex][fixedHeader] = members[memberIndex][fixedHeader] || [];
                                    members[memberIndex][fixedHeader] = members[memberIndex][fixedHeader].concat(self.splitAndTrim(row[header]));
                                }
                                break;
                            case "birthdate":
                                members[memberIndex][fixedHeader] = self.cleanDate(row[header]);
                                break;
                            default:
                                if (hasPartner) {
                                    members[memberIndex][fixedHeader] = row[header];
                                } else {
                                    members[memberIndex][fixedHeader] = members[memberIndex][fixedHeader] ?
                                        members[memberIndex][fixedHeader] + "/" + row[header] :
                                        row[header];
                                }
                        }
                    } else {
                        switch (header) {
                            case "memorials":
                                family.memorials = self.splitAndTrim(row[header]);
                                break;
                            case "familyDataCodes":
                                family.dataCodes = self.splitAndTrim(row[header]);
                                break;
                            case "anniversary":
                            case "dateJoined":
                            case "dateLastUpdated":
                                family[header] = self.cleanDate(row[header]);
                                break;
                            default:
                                family[header] = row[header];
                        }
                    }
                }
            }

            self.familiesStack.push(family);
            members = members.concat(children);
            for (let i in members) {
                self.membersStack.push(members[i]);
            }

            self.totalLinesProcessed++;
            self.totalMembersFound += members.length;
            self.totalFamiliesFound++;
        } catch (err) {
            self.errorStack.push({
                index,
                line,
                message: err.message,
            });
            self.setErrorStack(self.errorStack);
            self.totalLinesProcessed++;
            return;
        }
    },
    cleanDate: (originalDate) => {
        if (!originalDate || originalDate.length === 0) {
            return null;
        }
        return `${originalDate.substr(0,2)}/${originalDate.substr(3,2)}/${originalDate.substr(6,4)}`;
    },
    cleanPhone: (name, contactType, detail) => {
        if (!detail || detail.length === 0) {
            return null;
        }
        // remove spaces, hyphens, parentheses
        detail = detail
            .replace(/( |-|\(|\))/g, "");
        if (detail.indexOf('0') === 0) {
            detail = detail.replace('0', self.host.countryCode);
        }

        return {
            name,
            contactType,
            detail
        };
    },
    hasStringValue: (str) => {
        return (str != null && str.length > 0);
    },
    splitAndTrim: (str) => {
        if (!str) {
            return [];
        }
        return str.split(",").map(token => {
            return token.trim();
        });
    },
    tokenize: (line) => {
        // assuming ,"",,"","dfsdf"dsfsdf",
        let tokens = [];

        // loop through each character
        let lineLength = line.length;
        let token = "";
        let quotesOpen = false;
        for (let i = 0; i < lineLength; i++) {
            let ch = line.charAt(i);
            switch (ch) {
                case '"':
                    if (!quotesOpen) {
                        // if the previous character was a closing quote, we fail
                        if (i > 0 && line.charAt(i-1) === '"') {
                            throw new Error(`tokenize failed: bad quote at character ${i}`);
                        }

                        if (token.length === 0) {
                            // if we encounter a quote after starting a new token, open quotes
                            quotesOpen = true;
                        } else {
                            throw new Error(`tokenize failed: bad quote at character ${i}`);
                        }
                    } else {
                        // if we encounter a quote when quotes open
                        let nextChar = i < lineLength ? line.charAt(i+1) : "";
                        // if the following character signals the end of a token / line
                        switch (nextChar) {
                            case ",":
                            case ";":
                            case "":
                            case " ":
                                // close quotes, add to tokens and start a new token
                                quotesOpen = false;
                                break;
                            default:
                                // else convert to a single quote
                                token += "'";
                        }
                    }
                    break;
                case ',':
                    if (quotesOpen) {
                        token += ch;
                    } else {
                        // if we encounter a comma when quotes not open, add to tokens and start a new token
                        tokens.push(self.fixToken(token));
                        token = "";
                    }
                    break;
                default:
                    token += ch;
            }
        }
        // handle last token
        tokens.push(self.fixToken(token));
        return tokens;
    },
    fixToken: (token) => {
        // "" => null
        // "''abc''" => "abc"
        return token;
    },
    fixBoolean: (val) => {
        val = val || "";
        switch (val.toLowerCase()) {
            case "y":
            case "yes":
                return true;
            // no default
        }
        return false;
    },
    fixGender: (gender) => {
        gender = gender || "";
        switch (gender) {
            case "M":
                return "Male";
            case "F":
                return "Female";
            // no default
        }
        return "Not Specified";
    },
};

export default self;
