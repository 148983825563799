import ms from 'ms';

let refreshTokenPromise;

const self = {
    "hosts": {
        "temple-israel-za-cpt": {
            key: "temple-israel-za-cpt",
            name: "Temple Israel, Cape Town",
            url: "https://propolis.industrialcuriosity.com/api",
            countryCode: "+27",
            location: {
                name: "Cape Town",
                latitude: -33.9249,
                longitude: 18.4241
            }
        },
        "test-za": {
            key: "test-za",
            name: "Test Stack ZA",
            url: "https://dev.propolis.industrialcuriosity.com/api",
            countryCode: "+27",
            location: {
                name: "Cape Town",
                latitude: -33.9249,
                longitude: 18.4241
            }
        }
    },
    "register": (baseUrl) => {
        return `${baseUrl}/register`;
    },
    "confirm": (baseUrl) => {
        return `${baseUrl}/register/confirm`;
    },
    "login": (baseUrl) => {
        return `${baseUrl}/login`;
    },
    "resetPasswordRequest": (baseUrl) => {
        return `${baseUrl}/reset-password/request`;
    },
    "resetPassword": (baseUrl) => {
        return `${baseUrl}/reset-password`;
    },
    "refreshToken": (baseUrl) => {
        return `${baseUrl}/login/refresh`;
    },
    "users": (baseUrl) => {
        return `${baseUrl}/users`;
    },
    "user": (baseUrl, userId) => {
        return `${baseUrl}/users/${userId}`;
    },
    "roles": (baseUrl) => {
        return `${baseUrl}/roles`;
    },
    "statuses": (baseUrl) => {
        return `${baseUrl}/statuses`;
    },
    "members": (baseUrl) => {
        return `${baseUrl}/members`;
    },
    "families": (baseUrl) => {
        return `${baseUrl}/families`;
    },
    "member": (baseUrl, memberId) => {
        return `${baseUrl}/members/${memberId}`;
    },
    "family": (baseUrl, familyId) => {
        return `${baseUrl}/families/${familyId}`;
    },
    "membersBatch": (baseUrl) => {
        return `${baseUrl}/members-batch`;
    },
    "familiesBatch": (baseUrl) => {
        return `${baseUrl}/families-batch`;
    },
    "formatRequest": ({method, body, authToken}) => {
        let requestObject = {
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        if (body) {
            requestObject.body = (typeof body === 'object' ? JSON.stringify(body) : body);
        }
        if (authToken) {
            requestObject.headers['Authorization'] = `Bearer ${authToken}`;
        }
        return requestObject;
    },
    refreshAuthenticationToken: async (host, userSession) => {
        if (!refreshTokenPromise) {
            refreshTokenPromise = new Promise(async (resolve, reject) => {
                console.log(`attempting to refresh authentication token...`);
                let currentTimestamp = new Date().getTime();
                try {
                    let session = await (
                        await fetch(
                            self.refreshToken(host.url),
                            self.formatRequest({
                                method: "POST",
                                body: {
                                    userId: userSession.userId,
                                    refreshToken: userSession.refreshToken,
                                    deviceId: userSession.deviceId
                                }
                            })
                        )
                    ).json();
                    if (session.reason) {
                        console.log(`*ERROR* unable to refresh authentication token: ${session.reason}`);
                        return reject(new Error(session.reason));
                    }
                    console.log(`authentication token refreshed, setting expiration times...`);
                    // calculate expiration from the time requested
                    session.authTokenExpiration = currentTimestamp + ms(session.authTokenExpiration);
                    session.refreshTokenExpiration = currentTimestamp + ms(session.refreshTokenExpiration);
                    session.host = host.key;

                    resolve(session);
                } catch (err) {
                    reject(err);
                } finally {
                    refreshTokenPromise = null;
                }
            });
        }
        return refreshTokenPromise;
    }
};

export default self;
