import { useContext, useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';

import FamilyEditModal from './FamilyEditModal';
import MemberEditModal from './MemberEditModal';

import Paginator from './Paginator';

import { loadFromStorage, saveToStorage } from "../storage/local";

import api from '../utils/api';
import authorization from '../utils/authorization';
import fields from '../utils/fields';
import hosts from '../utils/hosts';

import tableSorter from '../utils/tableSorter';
import tokenRefresh from '../utils/tokenRefresh';

import {
    faPlusCircle,
    faSync,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import memberSync from '../utils/memberSync';

const iconPlusCircle = <FontAwesomeIcon icon={faPlusCircle} />;
const iconSync = <FontAwesomeIcon icon={faSync} />;
const iconTimesCircle = <FontAwesomeIcon icon={faTimesCircle} />;

const spacer = <>&nbsp;</>;

let baseFamilyList = {};
let baseSetFamilyList;
const reallySetFamilyList = (obj) => {
    baseFamilyList = {
        ...obj,
        nonce: Date.now()
    };
    baseSetFamilyList(baseFamilyList);
};

let baseMemberList = {};
let baseSetMemberList;
const reallySetMemberList = (obj) => {
    baseMemberList = {
        ...obj,
        nonce: Date.now()
    };
    baseSetMemberList(baseMemberList);
};

let baseFamilyLookup = {};
let baseSetFamilyLookup;
const reallySetFamilyLookup = (obj) => {
    baseFamilyLookup = {
        ...obj,
        nonce: Date.now()
    };
    baseSetFamilyLookup(baseFamilyLookup);
};

let debounceTimeout = null;

function MemberManagement (props) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, setHost ] = useContext(HostContext);

    const [ isRefreshingMemberList, setIsRefreshingMemberList ] = useState();

    const [ progressPercentage, setProgressPercentage ] = useState(0);
    const [ dirtyFamilyList, setDirtyFamilyList ] = useState(baseFamilyList);
    const [ dirtyMemberList, setDirtyMemberList ] = useState(baseMemberList);
    const [ dirtyFamilyLookup, setDirtyFamilyLookup ] = useState({});

    const [ memberListFilters, setMemberListFilters ] = useState([]);
    const [ filteredMemberList, setFilteredMemberList ] = useState(null);
    const [ areFiltersUpdated, setAreFiltersUpdated ] = useState(false);
    const [ isDebouncing, setIsDebouncing ] = useState(false);

    const [ pagination, setPagination ] = useState({
        offset: 0,
        limit: loadFromStorage('pagination_member_management_limit') || 25,
    });
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const query = new URLSearchParams(location.search);

    useEffect(() => {
        hosts.hostUpdate({ location, params, host, setHost, user, setUser, navigate });
        reloadScreen();
    }, [ host ]); // eslint-disable-line react-hooks/exhaustive-deps

    // for some reason we need to ensure that these calls are always to the
    // latest render
    baseSetFamilyList = setDirtyFamilyList;
    baseSetMemberList = setDirtyMemberList;
    baseSetFamilyLookup = setDirtyFamilyLookup;

    const setFamilyList = reallySetFamilyList;
    const setMemberList = reallySetMemberList;
    const setFamilyLookup = reallySetFamilyLookup;

    // remove nonces from latest lists
    let familyList = {
        ...dirtyFamilyList
    };
    delete familyList['nonce'];

    let memberList = {
        ...dirtyMemberList
    };
    delete memberList['nonce'];

    let familyLookup = {
        ...dirtyFamilyLookup
    };
    delete familyLookup['nonce'];

    const __DEV__ = query.get("dev") === "true";
    if (__DEV__) {
        console.warn(`---DEV MODE---`);
    }

    const setDelayedFilterUpdated = (source) => {
        console.log(`setDelayedFilterUpdated triggered by ${source}`);
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        debounceTimeout = setTimeout(() => {
            debounceTimeout = null;
            setIsDebouncing(false);
        }, 750);
        setAreFiltersUpdated(true);
        setIsDebouncing(true);
    };

    // check for user permissions
    const canEditMembers = authorization.hasRole(user, [authorization.ROLE.EDIT_MEMBERS]);
    const canViewMembers = canEditMembers || authorization.hasRole(user, [authorization.ROLE.VIEW_MEMBERS]);
    const canImportMembers = authorization.hasRole(user, [authorization.ROLE.IMPORT_MEMBERS]);

    if (tokenRefresh.isRefreshing) {
        return (
            <div className="px-3 py-4 flex justify-center">
                Session expired, re-authenticating...
            </div>
        );
    }

    // ensure updated pagination is stored
    if (pagination.limit !== loadFromStorage('pagination_member_management_limit')) {
        saveToStorage('pagination_member_management_limit', pagination.limit);
    }

    // token is not currently being refreshed
    const refreshAuthToken = async (next) => {
        if (!tokenRefresh.isRefreshing) {
            tokenRefresh.refresh({ host, user })
            .then((userSession) => {
                setUser(userSession);
                console.log(`refresh complete`);
                if (next && typeof next === "function") {
                    next();
                }
            })
            .catch(err => {
                toast.error(err);
                setUser({});
            });
        }
    };

    const handleFetchErr = async (err, next) => {
        if (err.message !== "Invalid/expired authentication token") {
            console.error(err);
            toast.error(err);
        } else {
            refreshAuthToken(next);
        }
    };

    const initializeFamilyList = () => {
        return new Promise(async (resolve, reject)=>{
            try {
                // request with {{host}}/families?initial_request=true
                // load the families but with "partial" flags, when loading
                // details for a family check those flags. if "partial",
                // request the actual data
                let json = await (
                    await fetch(
                        `${api.families(host.url)}?initial_request=true`,
                        api.formatRequest({ method: "GET", authToken: user.authToken })
                    )
                ).json();
                if (json.reason) {
                    throw new Error(json.reason);
                }

                for (let familyId in json)
                {
                    let family = json[familyId];
                    // add "partial" flag to indicate that this is incomplete info
                    // while updating the seen fields values so that the filters work
                    family.partial = true;

                    // TODO - until family initialize is implemented, comment out the following
                    // console.log(`adding family`, family);
                    // // update seen fields for filtering
                    // for (let key in family) {
                    //     switch (key) {
                    //         case "familyId":
                    //         case "updated":
                    //             break;
                    //         default:
                    //             console.log(`adding seen field ${key} = ${family[key]}`);
                    //             fields.addSeenField({
                    //                 name: key,
                    //                 value: family[key],
                    //                 source: "family"
                    //             });
                    //     }
                    // }
                }

                setFamilyList(json);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    };

    const initializeMemberList = () => {
        return new Promise(async (resolve, reject)=>{
            try {
                // request with {{host}}/members?initial_request=true
                // load the members but with "partial" flags, when loading
                // details for a member check those flags. if "partial",
                // request the actual data
                let json = await (
                    await fetch(
                        `${api.members(host.url)}?initial_request=true`,
                        api.formatRequest({ method: "GET", authToken: user.authToken })
                    )
                ).json();
                if (json.reason) {
                    throw new Error(json.reason);
                }

                let familyLookup = {};
                for (let memberId in json) {
                    let member = json[memberId];

                    // add "partial" flag to indicate that this is incomplete info
                    // while updating the seen fields values so that the filters work
                    member.partial = true;

                    // update seen fields for filtering
                    for (let key in member) {
                        switch (key) {
                            case "memberId":
                            case "updated":
                                break;
                            default:
                                fields.addSeenField({
                                    name: key,
                                    value: member[key],
                                    source: "member"
                                });
                        }
                    }

                    familyLookup[member.familyId] = familyLookup[member.familyId] || {};
                    familyLookup[member.familyId][memberId] = {
                        memberId,
                        familyName: member.familyName,
                        title: member.title,
                        firstNames: member.firstNames,
                        isDependant: member.isDependant
                    };
                }

                setMemberList(json);
                setFamilyLookup(familyLookup);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    };

    const getMembersList = async () => {
        if (canViewMembers && !memberSync.isSyncing) {
            setIsRefreshingMemberList(true);

            // if memberList is empty, initialize with basic details
            if (Object.keys(memberList).length === 0) {
                console.log(`initializing member list...`);
                initializeMemberList()
                .then(()=>{
                    console.log(`member list initialized`);
                })
                .catch(err=>{
                    toast.error(err.message);
                    handleFetchErr(err, getMembersList);
                });
            }

            // if familyList is empty, initialize with basic details
            if (Object.keys(familyList).length === 0) {
                console.log(`initializing family list...`);
                initializeFamilyList()
                .then(()=>{
                    console.log(`family list initialized`);
                })
                .catch(err=>{
                    toast.error(err.message);
                    handleFetchErr(err, getMembersList);
                });
            }

            // TODO now that we're initializing the list, don't update
            // it until the sync is complete
            // TODO but we do need to set the progress percentage...
            memberSync.sync({
                host,
                user,
                setUser,
                setFamilyList: ()=>{},
                setMemberList: ()=>{},
                setFamilyLookup: ()=>{},
                setProgressPercentage
            })
            .then(({ memberList, familyList, familyLookup }) => {
                setFamilyList(familyList);
                setMemberList(memberList);
                setFamilyLookup(familyLookup);
                setAreFiltersUpdated(true);
                console.log('sync complete');
            })
            .catch(err => {
                toast.error(err.message);
                handleFetchErr(err, getMembersList);
            })
            .finally(() => {
                setIsRefreshingMemberList(false);
            });
        }
    };

    const reloadScreen = () => {
        // initialize list of users
        getMembersList();
    };

    const deleteAllMembers = async () => {
        if (canEditMembers) {
            setIsRefreshingMemberList(true);
            try {
                let json = await (
                    await fetch(
                        api.members(host.url),
                        api.formatRequest({ method: "DELETE", authToken: user.authToken })
                    )
                ).json();
                console.log('delete all members response', json);
                if (json.reason) {
                    throw new Error(json.reason);
                }
                json = await (
                    await fetch(
                        api.families(host.url),
                        api.formatRequest({ method: "DELETE", authToken: user.authToken })
                    )
                ).json();
                console.log('delete all families response', json);
                if (json.reason) {
                    throw new Error(json.reason);
                }
                getMembersList();
            } catch (err) {
                setIsRefreshingMemberList(false);
                handleFetchErr(err, deleteAllMembers);
            }
        }
    };

    const addMemberListFilter = () => {
        setMemberListFilters([
            ...memberListFilters,
            {
                fields: [],
                text: ""
            }
        ]);
        setDelayedFilterUpdated('addMemberListFilter');
    };

    const removeMemberListFilter = (index) => {
        console.log(`removeMemberListFilter(${index})`);
        let updatedFilters = [
            ...memberListFilters
        ];
        updatedFilters.splice(index, 1);
        setMemberListFilters(updatedFilters);
        setDelayedFilterUpdated('removeMemberListFilter');
    };

    const updateMemberListFilters = (index, fields, text) => {
        // we never modify the filter text so that the filter displays what the user actually entered
        let updatedFilters = [
            ...memberListFilters
        ];
        updatedFilters[index].fields = fields;
        updatedFilters[index].text = text;

        // process text into filter tokens

        // tokens are lowercase, split on spaces but not when in quotes
        let lowerCaseText = (text || "").toString().toLowerCase();

        let tokens = [];
        let token = "";
        let inQuotes = false;
        for (let i = 0; i < lowerCaseText.length; i++) {
            let ch = lowerCaseText.charAt(i);
            switch (ch) {
                case '"':
                    // if this is an end quote or we have an opening quote mid-word
                    if (inQuotes || token.length > 0) {
                        // add word to tokens and start a new one
                        tokens.push(token);
                        token = "";
                    } else {
                        // if this is an opening quote we do nothing
                    }
                    inQuotes = !inQuotes;
                    break;
                case ' ':
                    if (inQuotes) {
                        // add to word
                        token += ch;
                    } else {
                        // only if we had other chars before
                        if (token.length > 0) {
                            // add word to tokens and start a new one
                            tokens.push(token);
                            token = "";
                        }
                    }
                    break;
                default:
                    // add to word
                    token += ch;
            }
        }
        // add the last word to the tokens
        if (token.length > 0) {
            tokens.push(token);
        }

        updatedFilters[index].tokens = tokens;
        //console.log(updatedFilters[index].tokens.join(","));

        setMemberListFilters(updatedFilters);
        setDelayedFilterUpdated('updateMemberListFilters');
        setPagination({
            ...pagination,
            offset: 0,
        });
    };

    const renderFilterItem = (item) => {
        // multiselect for fields, an input field for filter text, and a remove button
        let fieldOptions = [];
        let selectedFieldOptions = [];
        for (let field in fields.nameLookup) {
            let fieldOption = {
                label: fields.getName({ host, field }),
                value: field,
            };
            fieldOptions.push(fieldOption);
            if (item.fields.indexOf(field) > -1) {
                selectedFieldOptions.push(fieldOption);
            }
        }
        // sort field options alphabetically with "any" always being first
        fieldOptions.sort((a, b) => {
            if (a.value === "any") return -1;
            if (b.value === "any") return 1;
            if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
            if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
            return 0;
        });
        return (
            <div key={item.index} className="w-full flex flex-wrap content-start h-12">
                <MultiSelect
                    className="w-6/12 h-10 shadow appearance-none borderrounded"
                    options={fieldOptions}
                    value={selectedFieldOptions}
                    hasSelectAll={false}
                    onChange={(selectedFields) => {
                        // TODO how do we ensure that if a boolean gets selected, only booleans are selected?
                        // TODO or conversely, that booleans are deselected when non-booleans are selected?
                        console.log(`selectedFields`, selectedFields);
                        //setSelectedRole(userEntry.userId, selectedRoles);
                        updateMemberListFilters(
                            item.index,
                            selectedFields.map(option=>{
                                console.log(`selected field type ${fields.getType(option.value)}`);
                                return option.value;
                            }),
                            item.text
                        );
                    }}
                    labelledBy="Select Field"
                    disabled={isRefreshingMemberList}
                />
                <input className="w-5/12 h-10 shadow appearance-none borderrounded py-2 px-3"
                    id={`filter_${item.index}`}
                    type="text"
                    onChange={e => {
                        updateMemberListFilters(
                            item.index,
                            memberListFilters[item.index].fields,
                            e.target.value
                        );
                    }}
                    value={item.text}
                    disabled={isRefreshingMemberList}
                ></input>
                <button
                    className="w-1/12 h-12"
                    hidden={props.collapsed}
                    disabled={props.disabled}
                    onClick={() => {removeMemberListFilter(item.index);}}
                >
                    {iconTimesCircle}
                </button>
            </div>
        );
    };

    // return true on match, or false otherwise
    const hasFieldMatch = (entry, field, tokens) => {
        //console.log(entry.familyId, entry.memberId, field, lowerCaseFilter);

        // initialize false result for each token
        let results = [];
        for (let t in tokens) {
            results[t] = false;
        }

        let fieldType = fields.getType(field) || typeof entry[field];

        switch (fieldType) {
            case 'object':
                if (entry[field]) {
                    // filter with tokens
                    let objString = JSON.stringify(entry[field]).toLowerCase();
                    for (let t in tokens) {
                        if (objString.indexOf(tokens[t]) > -1) {
                            results[t] = true;
                        }
                    }
                }
                break;
            case 'boolean':
                // handle true, false and null
                for (let t in tokens) {
                    switch (tokens[t]) {
                        case "true":
                        case "yes":
                            results[t] = (entry[field] === true);
                            break;
                        case "false":
                        case "no":
                            results[t] = (entry[field] === false);
                            break;
                        case "null":
                        case "neither":
                        case "none":
                            results[t] = (entry[field] === null || entry[field] === undefined);
                            break;
                        // no default
                    }
                }
                break;
            case 'number':
                if (entry[field]) {
                    for (let t in tokens) {
                        // lowercase in case it's hexadecimal
                        if (entry[field].toString().toLowerCase().indexOf(tokens[t]) > -1) {
                            results[t] = true;
                        }
                    }
                }
                break;
            case 'string':
                if (entry[field]) {
                    for (let t in tokens) {
                        if (entry[field].toLowerCase().indexOf(tokens[t]) > -1) {
                            results[t] = true;
                        }
                    }
                }
                break;
            case 'array':
                if (entry[field]) {
                    // matches if a token has any partial match in the array
                    // loop through array looking for a match
                    for (let i in entry[field]) {
                        for (let t in tokens) {
                            if (entry[field][i].indexOf(tokens[t]) > -1) {
                                results[t] = true;
                            }
                        }
                    }
                }
                break;

            // no default
        }
        //console.log(`${fieldType} ${field} = ${tokens}: ${entry[field]} => ${results}`);

        // only return true if all results are true
        return results.reduce((total, val) => { return total && val; }, true);
    };

    const isMemberInFilter = (memberEntry, field, filterTokens) => {
        // TODO consider field type, arrays MIGHT need different handling
        //      and booleans too
        switch (field) {
            case "any":
                for (let key in fields.nameLookup) {
                    if (memberEntry[key]) {
                        if (hasFieldMatch(memberEntry, key, filterTokens)) {
                            return true;
                        }
                    }
                    if (familyList[memberEntry.familyId] && familyList[memberEntry.familyId][key]) {
                        if (hasFieldMatch(familyList[memberEntry.familyId], key, filterTokens)) {
                            return true;
                        }
                    }
                }
                break;
            default:
                if (hasFieldMatch(memberEntry, field, filterTokens)) {
                    return true;
                }
                if (familyList[memberEntry.familyId]) {
                    if (hasFieldMatch(familyList[memberEntry.familyId], field, filterTokens)) {
                        return true;
                    }
                }

        }

        return false;
    };

    let isFiltered = false;
    let activeMemberList = memberList;

    // active member list filters have text entered
    let activeMemberListFilters = [];
    for (let i in memberListFilters) {
        if (memberListFilters[i].fields.length > 0
            && memberListFilters[i].text.length > 0) {
            isFiltered = true;
            activeMemberListFilters.push(memberListFilters[i]);
        }
    }

    if (!isDebouncing && areFiltersUpdated) {
        if (isFiltered) {
            // initialize updatedFilteredMemberList with all members
            let updatedFilteredMemberList = {
                ...memberList
            };
            // treat each filter as an OR internally, but an AND with the other filters
            for (let memberId in memberList) {
                let memberEntry = memberList[memberId];
                // remove unloaded entries or entries without a filter match
                if (!memberEntry.familyId) {
                    delete updatedFilteredMemberList[memberId];
                } else {
                    // loop over filters
                    let isRemoved = false;
                    for (let filterIndex in activeMemberListFilters) {
                        if (!isRemoved) {
                            let filter = activeMemberListFilters[filterIndex];

                            // we OR over a filter's internal fields so track the results
                            let filterResult = null;
                            for (let fieldIndex in filter.fields) {
                                let field = filter.fields[fieldIndex];

                                // remove unloaded entries or entries without a filter match
                                filterResult = filterResult || isMemberInFilter(memberEntry, field, filter.tokens);
                            }
                            // if none of the filters matched, remove the entry
                            if (!filterResult) {
                                delete updatedFilteredMemberList[memberId];
                                isRemoved = true;
                            }
                        }
                    }
                }
            }
            setFilteredMemberList(updatedFilteredMemberList);
        } else {
            setFilteredMemberList(null);
        }
        setAreFiltersUpdated(false);
    }

    if (filteredMemberList != null) {
        activeMemberList = filteredMemberList;
    }
    let memberLength = activeMemberList ? Object.keys(activeMemberList).length : 0;

    let orderedArray = [];
    for (let memberId in activeMemberList) {
        if (typeof memberList[memberId] !== "object") {
            orderedArray.push({
                memberId,
                status: `${memberList[memberId]}`,
            });
        } else {
            orderedArray.push(memberList[memberId]);
        }
    }
    // sort array
    // loading to the back, errors to the front, otherwise sort by name, then email
    orderedArray.sort(
        tableSorter.default(
            'familyId',
            {
                'familyName': 'asc',
                'familyId': 'asc',
                'firstNames': 'asc'
            }
        )
    );

    let memberRows = [];
    for (let i = pagination.offset; i < pagination.offset + pagination.limit; i++) {
        let memberEntry = orderedArray[i];
        if (memberEntry) {
            if (!memberEntry.familyId) {
                // incomplete, details pending
                if (typeof memberEntry.status === "string" && memberEntry.status.toLowerCase().indexOf(`error`) > -1) {
                    memberRows.push(
                        <tr key={memberEntry.memberId} className="border-b hover:bg-orange-100 bg-red-300">
                            <td colSpan="4" className="p-3 px-5">{memberEntry.status}</td>
                        </tr>
                    );
                } else {
                    memberRows.push(
                        <tr key={memberEntry.memberId} className="border-b hover:bg-orange-100 bg-gray-100">
                            <td colSpan="4" className="p-3 px-5">Loading...</td>
                        </tr>
                    );
                }
            } else {
                memberRows.push(
                    <tr key={memberEntry.memberId} className="border-b hover:bg-orange-100 bg-gray-100">
                        <td className="p-3 px-5">
                            {canViewMembers ?
                                <FamilyEditModal
                                    members={familyLookup[memberEntry.familyId]}
                                    familyId={memberEntry.familyId}
                                    family={familyList[memberEntry.familyId]}
                                    readOnly={!canEditMembers}
                                    refreshAuthToken={refreshAuthToken}
                                    // TODO onUpdate should probably receive {family:, member:}
                                    // and call updateFamily / updateMember accordingly
                                    onUpdate={(updatedFamilyEntry) => {
                                        let updatedFamilyList = {
                                            ...familyList,
                                            [memberEntry.familyId]: JSON.parse(JSON.stringify(updatedFamilyEntry)),
                                        };
                                        for (let key in updatedFamilyEntry) {
                                            switch (key) {
                                                case "familyId":
                                                case "updated":
                                                    break;
                                                default:
                                                    fields.addSeenField({
                                                        name: key,
                                                        value: updatedFamilyEntry[key],
                                                        source: "family"
                                                    });
                                            }
                                        }
                                        setFamilyList(updatedFamilyList);
                                    }}
                                /> :
                                null
                            }
                        </td>
                        <td className="p-3 px-5">{memberEntry.familyName}</td>
                        <td className="p-3 px-5">{memberEntry.firstNames}</td>
                        <td className="p-3 px-5">{memberEntry.title ? memberEntry.title.join(", ") : null}</td>
                        <td className="p-3 px-5 flex justify-end">
                            {canViewMembers ? <MemberEditModal
                                member={memberEntry}
                                family={familyLookup[memberEntry.familyId]}
                                readOnly={!canEditMembers}
                                refreshAuthToken={refreshAuthToken}
                                onUpdate={(updatedMemberEntry) => {
                                    let updatedMemberList = {
                                        ...memberList,
                                        [memberEntry.memberId]: JSON.parse(JSON.stringify(updatedMemberEntry)),
                                    };
                                    for (let key in updatedMemberEntry) {
                                        switch (key) {
                                            case "memberId":
                                            case "updated":
                                                break;
                                            default:
                                                fields.addSeenField({
                                                    name: key,
                                                    value: updatedMemberEntry[key],
                                                    source: "member"
                                                });
                                        }
                                    }

                                    let updatedFamilyLookup = {
                                        ...familyLookup
                                    };
                                    updatedFamilyLookup[memberEntry.familyId][memberEntry.memberId] = {
                                        ...updatedFamilyLookup[memberEntry.familyId][memberEntry.memberId],
                                        familyName: updatedMemberEntry.familyName,
                                        firstNames: updatedMemberEntry.firstNames,
                                        title: updatedMemberEntry.title,
                                        isDependant: updatedMemberEntry.isDependant
                                    };

                                    setFamilyLookup(updatedFamilyLookup);
                                    setMemberList(updatedMemberList);
                                    setAreFiltersUpdated(true);
                                }}
                                onDelete={() => {
                                    let updatedMemberList = {
                                        ...memberList
                                    };
                                    delete updatedMemberList[memberEntry.memberId];

                                    let updatedFamilyLookup = {
                                        ...familyLookup
                                    };
                                    delete updatedFamilyLookup[memberEntry.familyId][memberEntry.memberId];
                                    let familySize = Object.keys(updatedFamilyLookup[memberEntry.familyId]).length;
                                    if (familySize === 0) {
                                        delete updatedFamilyLookup[memberEntry.familyId];
                                    }

                                    setFamilyLookup(updatedFamilyLookup);
                                    setMemberList(updatedMemberList);
                                    setAreFiltersUpdated(true);
                                }}
                            /> : null}
                        </td>
                    </tr>
                );
            }
        }
    }

    if (memberLength === 0 && !isRefreshingMemberList) {
        memberRows.push(
            <tr key="no-members-displayed" className="border-b hover:bg-orange-100 bg-red-300">
                <td colSpan="4" className="p-3 px-5">{isFiltered ? "No members match your filter" : "No members available."}</td>
            </tr>
        );
    }

    const goToMemberImport = () => {
        navigate("/members/import");
    };

    const refreshButton = (
        <button className="w-full bg-blue-400 hover:bg-blue-800 h-12 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={reloadScreen}
            disabled={memberSync.isSyncing}
        >
            {memberSync.isSyncing ?
                `Syncing...${progressPercentage > 0 && progressPercentage < 100 ? ` ${Math.trunc(progressPercentage)}%` : ''}` :
                `Refresh`}&nbsp;{iconSync}
        </button>
    );

    if (canViewMembers || canImportMembers) {
        return (
            <>
            {!memberList ?
                canViewMembers ?
                    isRefreshingMemberList ?
                        `Loading...` :
                        <div className="px-8 py-4 flex justify-between">
                            <div className="flex flex-col p-2 w-4/5">
                                `No members found`
                            </div>
                            <div className="flex flex-col p-2 w-2/5">
                                {refreshButton}
                            </div>
                        </div>
                : null
            : (
                <>
                <div className="px-8 py-4 flex justify-between">
                    <div className="flex flex-col p-2 w-4/5">
                        <div className="w-full h-12">
                            Filters
                            {spacer}
                            <button
                                disabled={Object.keys(memberList).length === 0}
                                onClick={addMemberListFilter}
                            >
                                {iconPlusCircle}
                            </button>
                        </div>
                        <div className="w-full">
                            {((items) => {
                                let result = [];
                                for (let i in items) {
                                    items[i].index = i;
                                    result.push(renderFilterItem(items[i]));
                                }
                                return result;
                            })(memberListFilters)}
                        </div>
                    </div>
                    <div className="flex flex-col p-2 w-2/5">
                        {refreshButton}
                        {__DEV__ ? <button className="w-full bg-red-400 hover:bg-red-800 h-12 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                            type="button"
                            onClick={deleteAllMembers}
                            disabled={isRefreshingMemberList}
                        >
                            DELETE ALL MEMBERS
                        </button> : null}
                    </div>
                </div>
                <Paginator
                    offset={pagination.offset}
                    limit={pagination.limit}
                    size={memberLength}
                    setPagination={setPagination}
                />
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                        <thead>
                            <tr className="border-b">
                                <th className="text-left p-3 px-5">Family</th>
                                <th className="text-left p-3 px-5">Family Name</th>
                                <th className="text-left p-3 px-5">First Names</th>
                                <th className="text-left p-3 px-5">Title(s)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {memberRows}
                        </tbody>
                    </table>
                </div>
                <Paginator
                    offset={pagination.offset}
                    limit={pagination.limit}
                    size={memberLength}
                    setPagination={setPagination}
                />
                </>
            )}
            {canImportMembers ? (
                <div className="px-3 py-4 flex justify-center">
                    <button className="p-4 hover:bg-blue-800 text-blue-400 font-bold py-2 px-4 rounded"
                        type="button"
                        onClick={goToMemberImport}
                    >
                        Member Import
                    </button>
                </div>
            ) : null}
            </>
        );
    } else {
        return (
            <>
                Access Denied: you do not have the required authorization to view this page.
            </>
        );
    }
}

export default MemberManagement;
