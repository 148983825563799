import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';
import ProgressBar from './ProgressBar';
import { deleteFromStorage, loadFromStorage } from "../storage/local";

import hosts from '../utils/hosts';

import { toast } from 'react-toastify';

import authorization from '../utils/authorization';
import memberImporter from '../utils/memberImport';

const spacer = <>&nbsp;</>;

const pageInitialLoadTime = Date.now();

function MemberImport (/*props*/) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, setHost ] = useContext(HostContext);
    const [ file, setFile ] = useState();
    const [ headers, setHeaders ] = useState();
    const [ sampleData, setSampleData] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isReady, setIsReady ] = useState(false);
    const [ isImporting, setIsImporting ] = useState(false);
    const [ errorStack, setErrorStack] = useState([]);
    const [ isImportComplete, setIsImportComplete ] = useState(false);
    const [ progressPercentage, setProgressPercentage ] = useState(0);
    const [ cancellationSignal, setCancellationSignal ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const query = new URLSearchParams(location.search);

    const __DEV__ = query.get("dev") === "true";
    if (__DEV__) {
        console.warn(`---DEV MODE---`);
    }

    console.log(`errorStack`, errorStack);

    useEffect(() => {
        hosts.hostUpdate({ location, params, host, setHost, user, setUser, navigate });
    }, [ host ]); // eslint-disable-line react-hooks/exhaustive-deps

    // check for user permissions
    if (!authorization.hasRole(user, [authorization.ROLE.IMPORT_MEMBERS])) {
        return (
            <>
                Access Denied: you do not have the required authorization to view this page.
            </>
        );
    }

    const logoutIfNotRememberMe = () => {
        let signOut = (msg) => {
            console.log(msg);
            deleteFromStorage('userSession');
            setUser({});
        };
        if (user.refreshTokenExpiration - pageInitialLoadTime <= 0) {
            signOut('page refreshed with expired refresh token, signing out');
        } else {
            if (!loadFromStorage('rememberMe').remember) {
                signOut('page refreshed with remember me not flagged, signing out');
            }
        }
    };
    if (user.authTokenExpiration - pageInitialLoadTime <= 0) logoutIfNotRememberMe();

    const getHeadersArray = () => {
        let result = [];
        if (!headers) return result;
        for (let h in headers) {
            if (headers[h].selected) {
                result.push(headers[h].custom || headers[h].fixed);
            } else {
                result.push(null);
            }
        }
        return result;
    };

    const loadHeaders = async (file) => {
        setIsLoading(true);
        setErrorStack([]);
        memberImporter.setFile(file)
        .then(() => {
            memberImporter.getHeaders({
                setErrorStack
            })
            .then(loadedHeaders => {
                setHeaders(loadedHeaders);
                memberImporter.getSampleData({
                    headers: loadedHeaders,
                    setErrorStack,
                })
                .then(result=>{
                    setSampleData(result);
                    setIsReady(true);
                    toast.success("headers loaded successfully");
                })
                .catch(err=>{
                    toast.error(err.message);
                    setIsReady(false);
                });
            })
            .catch(err=>{
                toast.error(err.message);
                setIsReady(false);
            })
            .then(()=>{
                setIsLoading(false);
            });
        })
        .catch(err=>{
            toast.error(err.message);
            setIsLoading(false);
        });
    };
    const retryFileLoad = () => {
        setHeaders();
        setIsReady(false);
        setCancellationSignal(false);
        loadHeaders(file);
    };
    const onFileChange = (event) => {
        // clear all other things
        setHeaders();
        setIsReady(false);
        setCancellationSignal(false);
        // TODO validate csv file
        if (event
            && event.target
            && event.target.files
            && event.target.files.length > 0) {
            setFile(event.target.files[0]);
            loadHeaders(event.target.files[0]);
        }
    };

    const performImport = async () => {
        setIsImporting(true);
        setIsImportComplete(false);
        // see https://www.npmjs.com/package/line-navigator#constructor if there are encoding issues

        memberImporter.setHeaders(getHeadersArray());

        memberImporter.import({
            host,
            user, setUser,
            setProgressPercentage,
            setErrorStack
        })
        .then(()=>{
            setIsImportComplete(true);
        })
        .catch(err=>{
            toast.error(err.message);
        })
        .then(() => {
            setIsImporting(false);
        });
    };

    const stopImport = async () => {
        memberImporter.cancel();
        setIsImporting(false);
    };

    let importMembersFile = {
        "stopButton":
            <button className="p-4 bg-red-400 hover:bg-red-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                type="button"
                onClick={stopImport}
                disabled={!isImporting}
            >
                Stop Import
            </button>,
    };

    const fileCaptureButton = (
        <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
            <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">Select a file</span>
                <input type='file'
                    className="hidden"
                    onChange={onFileChange}
                    disabled={isLoading || isImporting}
                />
            </label>
        </div>
    );

    const retryFileLoadButton = (
        <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={retryFileLoad}
            disabled={isLoading || isImporting}
        >
            Reload File
        </button>
    );

    const updateHeader = (index, field, value) => {
        // clone headers array
        let updatedHeaders = JSON.parse(JSON.stringify(headers));
        console.log(`set '${field}' of '${updatedHeaders[index].custom || updatedHeaders[index].fixed}' (${index}) to '${value}'`);
        updatedHeaders[index][field] = value;
        setHeaders(updatedHeaders);
    };

    let headerTable;
    let numSelectedInvalidHeaders = 0;
    if (headers && !isImporting && !isImportComplete) {
        let headerRows = [];
        for (let i in headers) {
            let headerEntry = headers[i];
            let sampleDataEntry = (sampleData || [])[i];
            let selectedElement = (
                <input className="form-checkbox"
                    type="checkbox"
                    defaultChecked={headerEntry.selected}
                    onChange={(e) => { updateHeader(i, 'selected', e.target.checked); }}
                />
            );
            let customEntry = (
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-800"
                    key={`custom_header_${i}`}
                    id={`custom_header_${i}`}
                    type="text"
                    placeholder={headerEntry.fixed || "Invalid Header"}
                    onChange={e => { updateHeader(i, 'custom', e.target.value); }}
                    value={headerEntry.custom}
                    disabled={isLoading || isImporting}
                ></input>
            );
            if (headerEntry.fixed.length === 0
                && !(headerEntry.custom && headerEntry.custom.length > 0)) {
                headerRows.push(
                    <tr key={`header_row_${i}`} className="border-b hover:bg-orange-100 bg-red-300">
                        <td className="p-3 px-5">{selectedElement}</td>
                        <td className="p-3 px-5">{headerEntry.original}</td>
                        <td>{customEntry}</td>
                        <td>{sampleDataEntry}</td>
                    </tr>
                );
                if (headerEntry.selected) {
                    numSelectedInvalidHeaders++;
                }
            } else {
                headerRows.push(
                    <tr key={`header_row_${i}`} className="border-b hover:bg-orange-100 bg-gray-100">
                        <td className="p-3 px-5">{selectedElement}</td>
                        <td className="p-3 px-5">{headerEntry.original}</td>
                        <td>{customEntry}</td>
                        <td>{sampleDataEntry}</td>
                    </tr>
                );
            }
        }
        headerTable = (
            <div className="flex justify-center">
                <div className="w-full px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                        <tbody>
                            <tr className="border-b">
                                <th className="text-left p-3 px-5">Selected</th>
                                <th className="text-left p-3 px-5">Original Column Name</th>
                                <th className="text-left p-3 px-5">New Column Name</th>
                                <th className="text-left p-3 px-5">Sample Data</th>
                            </tr>
                            {headerRows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    let errorTable;
    /*
        self.errorStack.push({ request, message: err.message });
        self.errorStack.push({
            index,
            line,
            message: `Cannot process empty line.`
        });
        self.errorStack.push({
            index,
            line,
            message: `Special entry, cannot process.`
        });
    */

    const isMissingRequiredHeaders = () => {
        let finalHeaders = getHeadersArray();
        for (let rh in memberImporter.requiredHeaders) {
            if (finalHeaders.indexOf(memberImporter.requiredHeaders[rh]) < 0) {
                return true;
            }
        }
        return false;
    };

    // show warning on disabled import button if invalid headers are selected
    let importButton;
    if (numSelectedInvalidHeaders > 0 || isMissingRequiredHeaders()) {
        importButton = (
            <button className="p-4 bg-red-400 hover:bg-red-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                type="button"
                disabled={true}
            >
                {isMissingRequiredHeaders() ?
                    "Missing required fields" :
                    `${numSelectedInvalidHeaders} Invalid Rows Selected`
                }
            </button>
        );
    } else {
        importButton = (
            <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                type="button"
                onClick={performImport}
                disabled={!isReady || isLoading || isImporting || isImportComplete || cancellationSignal}
            >
                { isImportComplete ? `Import Complete`: `Begin Import` }
            </button>
        );
    }

    return (
        <div>
            <div className="flex items-center">
                {fileCaptureButton}
                <div className="flex flex-col w-max">
                    {file ? `File: ${file.name}` : ''}
                    {isImporting ? spacer : ''}
                    {isImporting ? <ProgressBar percentage={progressPercentage} color="blue" /> : ''}
                    {isImporting ? importMembersFile.stopButton : ''}
                </div>
                <div className="flex flex-col w-max">
                    {file && !isLoading ? retryFileLoadButton : ''}
                    {file ? importButton : ''}
                </div>
            </div>
            {headerTable}
            {errorTable}
        </div>
    );
}

export default MemberImport;