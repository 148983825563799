const NAMESPACE = "propolis-";

export const loadFromStorage = (key) => {
    key = NAMESPACE + key;
    if (localStorage[key]) {
        return JSON.parse(localStorage[key]);
    }
    return null;
};

export const saveToStorage = (key, json) => {
    key = NAMESPACE + key;
    localStorage[key] = JSON.stringify(json);
};

export const deleteFromStorage = (key) => {
    key = NAMESPACE + key;
    localStorage.removeItem(key);
};
