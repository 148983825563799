import React, { useState, createContext } from 'react';
import { loadFromStorage } from "../storage/local";

export const HostContext = createContext();

export const HostProvider = (props) => {
    const [ host, setHost ] = useState(loadFromStorage('host'));

    return (
        <HostContext.Provider value={[ host, setHost ]}>
            {props.children}
        </HostContext.Provider>
    );
};
