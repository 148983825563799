import { Fragment, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';

import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';

import api from '../utils/api';
import authorization from '../utils/authorization';
import fields from '../utils/fields';
import tokenRefresh from '../utils/tokenRefresh';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faSync,
  faTimes,
  faTimesCircle,
  faUserAlt,
  faUserEdit
} from '@fortawesome/free-solid-svg-icons';

const spacer = <>&nbsp;</>;

const iconPlusCircle = <FontAwesomeIcon icon={faPlusCircle} />;
const iconSync = <FontAwesomeIcon icon={faSync} />;
const iconTimes = <FontAwesomeIcon icon={faTimes} />;
const iconTimesCircle = <FontAwesomeIcon icon={faTimesCircle} />;
const iconUserAlt = <FontAwesomeIcon icon={faUserAlt} />;
const iconUserEdit = <FontAwesomeIcon icon={faUserEdit} />;

export default function MemberEditModal(props) {
  const [ host ] = useContext(HostContext);
  const [ user, setUser ] = useContext(UserContext);
  const [ showModal, setShowModal ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ isRefreshing, setIsRefreshing ] = useState(false);
  const [ isUpdated, setIsUpdated ] = useState(false);
  // use stringify/parse to clone object safely
  const [ member, setMember ] = useState({
    ...JSON.parse(JSON.stringify(props.member || {}))/*,
    ...{testUnknownField: 'sure'}*/
  });

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const __DEV__ = query.get("dev") === "true";
  if (__DEV__) {
      console.warn(`---DEV MODE---`);
  }

  const canDeleteMembers = authorization.hasRole(user, [authorization.ROLE.DELETE_MEMBERS]);

  let validationErrors = [];

  let family = props.family || {};
  let familySize = family ? Object.keys(family).length : 0;
  let familyElements = [];
  if (familySize > 1) {
    familyElements.push(
        <span
          key={`family_header_${member.memberId}`}
          className="font-bold">Family members:</span>
    );
    for (let memberId in family) {
        if (memberId !== member.memberId) {
          familyElements.push(
            <Fragment key={`fam_${member.memberId}_${memberId}`}>
              <br />
              <i>{family[memberId].familyName}, {family[memberId].firstNames}</i>
            </Fragment>
          );
        }
    }
  }

  const cancel = (mustClose) => {
    // use stringify/parse to clone object safely
    console.log(`closing (${mustClose})`);
    setMember(JSON.parse(JSON.stringify(props.member || {})));
    setIsUpdated(false);
    if (mustClose) {
      setShowModal(false);
    }
  };

  //console.log(`session token: ${ms(user.authTokenExpiration - Date.now())} remaining`);

  const refreshAuthToken = () => {
    return new Promise(async (resolve, reject) => {
      tokenRefresh.refresh({ host, user })
      .then((userSession) => {
        setUser(userSession);
        console.log(`refresh complete`);
        resolve();
      })
      .catch(err => {
        toast.error(err);
        setUser({});
        reject();
      });
    });
  };

  const refreshMember = async () => {
    setIsRefreshing(true);
    let json = await (
      await fetch(
        api.member(host.url, member.memberId),
        api.formatRequest({ method: "GET", authToken: user.authToken })
      )
    ).json();
    if (json.reason) {
      switch (json.reason) {
        case "Invalid/expired authentication token":
          await refreshAuthToken();
          refreshMember();
          return;
        default:
          toast.error(json.reason);
      }
    } else {
      setMember(json);
      setIsUpdated(false);
      props.onUpdate(json);
    }
    setIsRefreshing(false);
  };

  const deleteFamily = async () => {
    try {
      let json = await (
        await fetch(
          api.family(host.url, member.familyId),
          api.formatRequest({
            method:"DELETE",
            authToken: user.authToken
          })
        )
      ).json();
      if (json.reason) {
        switch (json.reason) {
          case "Invalid/expired authentication token":
            await refreshAuthToken();
            await deleteFamily();
            return;
          default:
            toast.error(json.reason);
        }
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const deleteMember = async () => {
    setIsSaving(true);
    try {
      // delete member, on success hide modal and call "onDelete" with the response body
      let json = await (
        await fetch(
          api.member(host.url, member.memberId),
          api.formatRequest({
            method:"DELETE",
            authToken: user.authToken
          })
        )
      ).json();
      if (json.reason) {
        switch (json.reason) {
          case "Invalid/expired authentication token":
            await refreshAuthToken();
            await deleteMember();
            return;
          default:
            toast.error(json.reason);
        }
      } else {
        if (familySize === 1) {
          await deleteFamily();
        }
        setIsUpdated(false);
        setShowModal(false);
        props.onDelete(member);
      }
    } catch (err) {
      toast.error(err);
    }
    setIsSaving(false);
  };

  const save = async () => {
    if (validationErrors.length > 0) {
      toast.error(`Please review validation errors.`);
      console.error(validationErrors);
      return;
    }
    setIsSaving(true);
    try {
      // update member, on success call "onUpdate" with the response body
      let json = await (
        await fetch(
          api.member(host.url, member.memberId),
          api.formatRequest({
            method:"PUT",
            body: member,
            authToken: user.authToken
          })
        )
      ).json();
      if (json.reason) {
        switch (json.reason) {
          case "Invalid/expired authentication token":
            await refreshAuthToken();
            save();
            return;
          case "Member details outdated":
            toast.warn("Member details outdated, reloading...");
            refreshMember();
            break;
          default:
            toast.error(json.reason);
        }
      } else {
        setIsUpdated(false);
        setMember(json);
        props.onUpdate(json);
      }
    } catch (err) {
      toast.error(err);
    }
    setIsSaving(false);
  };

  const createInputLabel = (key, name) => {
    let cleanKey = key.replace(" ", "_");
    return (
      <label className="block text-grey-800 text-sm font-bold mb-2" htmlFor={cleanKey}>
          {name}
      </label>
    );
  };

  const updateMemberValue = (key, value) => {
    let updatedMember = {
      ...member,
      [key]: value,
    };
    if (['familyName', 'firstNames'].indexOf(key) > -1) {
      updatedMember['name'] = `${updatedMember.firstNames} ${updatedMember.familyName}`;
    }
    setMember(updatedMember);
    setIsUpdated(true);
  };

  const createInputElement = (key, keyType, name, hasPrivateValues) => {
    let cleanKey = key.replace(" ", "_");
    switch (keyType) {
      case "string":
        //fallthrough
      case "number":
        // TODO cast input or show error
        return (
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-800"
            key={cleanKey}
            id={cleanKey}
            type="text"
            placeholder={name || key}
            onChange={e => { updateMemberValue(key, e.target.value); }}
            value={member[key] || ""}
            disabled={props.readOnly}
          ></input>
        );
      case "boolean":
        return (
          <div
            key={cleanKey}
            id={cleanKey}
          >
            {name}
            {spacer}
            <input className="form-checkbox"
              type="checkbox"
              checked={member[key] || false}
              onChange={e => { updateMemberValue(key, e.target.checked); }}
              disabled={props.readOnly}
              />
          </div>
        );
      case "array":
        let publicOptions = fields.getSeenValues(key).map(val=>{
          return {
            label: val,
            value: val,
          };
        });
        let memberOptions = (member[key] || []).map(val=>{
          return {
            label: val,
            value: val,
          };
        });
        return (
          <div
            key={cleanKey}
            id={cleanKey}
          >
            <MultiSelect
              options={hasPrivateValues ? memberOptions : publicOptions}
              value={memberOptions}
              onChange={(selectedTags) => {
                selectedTags = selectedTags.map(obj => obj.value);
                for (let i in selectedTags) {
                  fields.addSeenValue(key, selectedTags[i]);
                }
                updateMemberValue(key, selectedTags);
              }}
              labelledBy="Select Tag"
              disabled={props.readOnly}
              isCreatable={true}
              onCreateOption={(value) => ({
                  label: value,
                  value: value
              })}
            />
          </div>
        );
      default:
        return (
          <i>Unable to provide form field for <b>{fields.getName({ host, field: key})}</b>.</i>
        );
    }
  };

  let memberFormFields = [];

  // TODO form validation, contact details, different field types, saving

  // build out required fields, then loop through any keys that haven't been used
  let managedFields = [
    'memberId',
    'familyId',
    'updated',
    'isDependant',
    'familyName',
    'firstNames',
    'name',
    'title',
    'birthdate',
    'jewishBday', // TODO calculate from birthdate if available
    'jewishBdayCivil', // calculate this for the current year
    'contactDetails',
    'dataCodes',
    'hobbies',
    'memorials',
  ];

  // names and titles
  memberFormFields.push(
    <div key={`familyName_${member.memberId}`} className="mb-4">
      {createInputLabel('familyName', fields.getName({ host, field: 'familyName'}))}
      {createInputElement('familyName', 'string', 'Family name')}
    </div>
  );
  memberFormFields.push(
    <div key={`firstNames_${member.memberId}`} className="mb-4">
      {createInputLabel('firstNames', fields.getName({ host, field: 'firstNames'}))}
      {createInputElement('firstNames', 'string', 'First names')}
    </div>
  );
  // titles
  memberFormFields.push(
    <div key={`title_${member.memberId}`} className="mb-4">
      {createInputLabel('title', fields.getName({ host, field: 'title'}))}
      {createInputElement('title', 'array', 'Titles')}
    </div>
  );

  memberFormFields.push(
    <div key={`birthdate_${member.memberId}`} className="mb-4">
      {createInputLabel('birthdate', fields.getName({ host, field: 'birthdate'}))}
      {createInputElement('birthdate', 'string', 'DD/MM/YYYY')}
    </div>
  );
  //jewishBday - hebrew date (english / hebrew)
  memberFormFields.push(
    <div key={`jewishBday_${member.memberId}`} className="mb-4">
      {createInputLabel('jewishBday', fields.getName({ host, field: 'jewishBday'}))}
      {createInputElement('jewishBday', 'string', 'Hebrew Month Day')}
    </div>
  );
  // TODO jewishBdayCivil - english date for the current year

  // CONTACT DETAILS
  const createContactTypeSelect = (i) => {
    return (
      <select
        key={`contactDetail_${i}_type`}
        id={`contactDetail_${i}_type`}
        value={member.contactDetails[i].contactType}
        className="bg-transparent w-24"
        onChange={(event) => {
          let tempContactDetails = member.contactDetails;
          tempContactDetails[i].contactType = event.target.value;
          updateMemberValue('contactDetails', tempContactDetails);
        }}
        disabled={props.readOnly}
      >
        <option value={`email`}>email</option>
        <option value={`mobile`}>mobile</option>
        <option value={`telephone`}>telephone</option>
      </select>
    );
  };

  const createContactNameInput = (i) => {
    return (
      <input className="shadow appearance-none border rounded w-36 py-2 px-3 text-grey-800"
        key={`contactDetail_${i}_name`}
        id={`contactDetail_${i}_name`}
        type="text"
        placeholder={`Name eg. "home" or "work"`}
        onChange={(event) => {
          let tempContactDetails = member.contactDetails;
          tempContactDetails[i].name = event.target.value;
          updateMemberValue('contactDetails', tempContactDetails);
        }}
        value={member.contactDetails[i].name}
        disabled={props.readOnly}
      ></input>
    );
  };

  const createContactDetailInput = (i) => {
    return (
      <input className="shadow appearance-none border rounded w-72 py-2 px-3 text-grey-800"
        key={`contactDetail_${i}_detail`}
        id={`contactDetail_${i}_detail`}
        type="text"
        placeholder={"Email address or telephone number"}
        onChange={(event) => {
          let tempContactDetails = member.contactDetails;
          tempContactDetails[i].detail = event.target.value;
          updateMemberValue('contactDetails', tempContactDetails);
        }}
        value={member.contactDetails[i].detail}
        disabled={props.readOnly}
      ></input>
    );
  };

  let contactDetailsEntries = [];
  for (let i in member.contactDetails) {
    contactDetailsEntries.push(
      <div key={`contact_${member.memberId}_${i}`} className=" pb-3">
        {createContactTypeSelect(i)}
        {spacer}
        {createContactNameInput(i)}
        {spacer}
        {createContactDetailInput(i)}
        {spacer}
        {props.readOnly ? null : <button
            className="p-4 bg-red-400 hover:bg-red-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
            disabled={props.readOnly}
            onClick={() => {
              let tempContactDetails = member.contactDetails;
              tempContactDetails.splice(i, 1);
              updateMemberValue('contactDetails', tempContactDetails);
            }}
        >
            {iconTimesCircle}
        </button>}
      </div>
    );
  }
  // + to add a contact detail
  let contactDetailsElements = (
    <div key={`contact_${member.memberId}`} className="block text-grey-800 text-sm font-bold mb-2">
      Contact Details
      {spacer}
      {props.readOnly ? null : <button
          className="pb-3"
          disabled={isRefreshing}
          onClick={() => {
            let tempContactDetails = member.contactDetails || [];
            tempContactDetails.push({
              contactType: 'email',
              detail: '',
              name: '',
            });
            updateMemberValue('contactDetails', tempContactDetails);
          }}
        >
          {iconPlusCircle}
      </button>}
      <br />
      {contactDetailsEntries}
    </div>
  );
  memberFormFields.push(contactDetailsElements);

  // data codes / tags
  memberFormFields.push(
    <div key={`dataCodes_${member.memberId}`} className="mb-4">
      {createInputLabel('dataCodes', fields.getName({ host, field: 'dataCodes'}))}
      {createInputElement('dataCodes', 'array', 'Data Codes/Tags')}
    </div>
  );

  // handle any unmanaged fields
  for (let key in member) {
    if (managedFields.indexOf(key) < 0) {
      let labelElement = createInputLabel(key, fields.getName({ host, field: key}));
      let inputElement = createInputElement(key, typeof member[key], fields.getName({ host, field: key}));
      if (inputElement) {
        memberFormFields.push(
          <div key={`unmanaged_${key}_${member.memberId}`} className="mb-4">
            {labelElement}
            {inputElement}
          </div>
        );
      }
    }
  }

  // hobbies
  memberFormFields.push(
    <div key={`hobbies_${member.memberId}`} className="mb-4">
      {createInputLabel('hobbies', fields.getName({ host, field: 'hobbies'}))}
      {createInputElement('hobbies', 'array', 'Hobbies')}
    </div>
  );

  // memorials
  memberFormFields.push(
    <div key={`memorials_${member.memberId}`} className="mb-4">
      {createInputLabel('memorials', fields.getName({ host, field: 'memorials'}))}
      {createInputElement('memorials', 'array', 'Memorials', true)}
    </div>
  );

  const editMemberButton = (
    <button
      className="bg-blue-400 hover:bg-blue-800 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      type="button"
      onClick={() => {
        // refresh member data before displaying the modal
        setMember(JSON.parse(JSON.stringify(props.member || {})));
        setShowModal(true);
      }}
    >
      Details&nbsp;{props.readOnly ? iconUserAlt : iconUserEdit}
    </button>
  );

  let formActionButtons = props.readOnly || !isUpdated ?
    (
      <>
        {canDeleteMembers ? <button
          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={()=>{setIsDeleting(true);}}
          disabled={isSaving || isRefreshing}
        >
          Delete
        </button> : null}
        <button
          className="bg-blue-400 hover:bg-blue-800 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={()=>{cancel(true);}}
          disabled={isSaving || isRefreshing}
        >
          Close
        </button>
      </>
    ) :
    (
      <>
        <button
          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={()=>{cancel(false);}}
          disabled={isSaving || isRefreshing}
        >
          Cancel
        </button>
        <button
          className={`${validationErrors.length > 0 ? "bg-red-400" : "bg-blue-400"} ${validationErrors.length > 0 ? "hover:bg-red-800" : "hover:bg-blue-800"} text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
          type="button"
          onClick={save}
          disabled={isSaving || isRefreshing || validationErrors.length > 0}
        >
          Save Changes
        </button>
      </>
    );

  let modalContent;
  if (showModal) {
    if (isDeleting) {
      modalContent = (
        <>
          <div
            className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    {member.title && member.title.length > 0 ? `${member.title.join("/")} ` : null}{member.firstNames} {member.familyName}
                  </h3>
                  <div className="flex">
                    <button
                      className="p-0 ml-auto text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={refreshMember}
                      disabled={isSaving || isRefreshing}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        {iconSync}
                      </span>
                    </button>
                    {spacer}
                    <button
                      className="px-5 ml-auto text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={()=>{cancel(true);}}
                      disabled={isSaving || isRefreshing}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        {iconTimes}
                      </span>
                    </button>
                  </div>
                </div>
                {/*body*/}
                <div className="px-3 py-4 flex justify-center font-bold">
                    WARNING: Deleting a member entry is IRREVERSIBLE.
                </div>
                <div className="px-3 py-4 flex justify-center">
                    Are you sure you want to proceed?
                </div>
                <div className="px-3 py-4 flex justify-center">
                  <button
                    className={"bg-blue-400 hover:bg-blue-800 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"}
                    type="button"
                    onClick={()=>{setIsDeleting(false);}}
                    disabled={isSaving || isRefreshing}
                  >
                    Cancel
                  </button>
                  <button
                    className={"bg-red-400 hover:bg-red-800 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"}
                    type="button"
                    onClick={deleteMember}
                    disabled={isSaving || isRefreshing}
                  >
                    Delete Member
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      );
    } else if (tokenRefresh.isRefreshing) {
      modalContent = (
        <>
          <div
            className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    {member.title && member.title.length > 0 ? `${member.title.join("/")} ` : null}{member.firstNames} {member.familyName}
                  </h3>
                  <div className="flex">
                    <button
                      className="p-0 ml-auto text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={refreshAuthToken}
                      disabled={tokenRefresh.isRefreshing}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        {iconSync}
                      </span>
                    </button>
                  </div>
                </div>
                {/*body*/}
                <div className="px-3 py-4 flex justify-center">
                    Session expired, re-authenticating...
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      );
    } else {
      modalContent = (
        <>
          <div
            className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    {member.title && member.title.length > 0 ? `${member.title.join("/")} ` : null}{member.firstNames} {member.familyName}
                  </h3>
                  <div className="flex">
                    <button
                      className="p-0 ml-auto text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={refreshMember}
                      disabled={isSaving || isRefreshing}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        {iconSync}
                      </span>
                    </button>
                    {spacer}
                    <button
                      className="px-5 ml-auto text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={()=>{cancel(true);}}
                      disabled={isSaving || isRefreshing}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        {iconTimes}
                      </span>
                    </button>
                  </div>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-y-auto h-2/4">
                  <p className="my-4 text-blueGray-500 text-sm mb-2 leading-relaxed">
                    {member.isDependant ? (
                      <>
                        <i>(Dependant)</i>
                        <br /><br />
                      </>
                    ) : null}
                    {familyElements}
                  </p>
                  {memberFormFields}
                </div>
                {/*footer*/}
                { validationErrors.length > 0 ?
                  <div className="flex items-center justify-start p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <ul className="text-red-500 text-xs italic">
                      { validationErrors.map(str => {
                        return <li className="text-red-500 text-xs italic">{str}</li>;
                      }) }
                    </ul>
                  </div>
                 : null }
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  { formActionButtons }
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      );
    }
  }

  return (
    <>
      {editMemberButton}
      {modalContent}
    </>
  );
}