import { useContext } from 'react';
import { UserContext } from '../contexts/User';
import { HostContext } from '../contexts/Host';
import { deleteFromStorage } from "../storage/local";
import { useNavigate } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import authorization from '../utils/authorization';

function Header (/*props*/) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, ] = useContext(HostContext);
    const navigate = useNavigate();
    let hostName = host ? host.name : '';

    const goToDefaultPage = () => {
        navigate("/");
    };

    const goToMemberManagement = () => {
        navigate("/members");
    };

    const goToUserManagement = () => {
        navigate("/users");
    };

    const logout = () => {
        console.log(`header logging out`);
        deleteFromStorage('userSession');
        setUser({});
        navigate("/");
    };

    let buttons = {};
    if (user && user.name) {
        if (authorization.hasRole(user, [
            authorization.ROLE.EDIT_MEMBERS,
            authorization.ROLE.VIEW_MEMBERS,
            authorization.ROLE.IMPORT_MEMBERS,
        ])) {
            buttons.memberManagement = (
                <div>
                    <button className="p-4 hover:bg-blue-800 text-blue-400 font-bold py-2 px-4 rounded"
                        type="button"
                        onClick={goToMemberManagement}
                    >
                        Member Management
                    </button>
                </div>
            );
        }
        if (authorization.hasRole(user, [
            authorization.ROLE.EDIT_USERS,
            authorization.ROLE.VIEW_USERS,
        ])) {
            buttons.userManagement = (
                <div>
                    <button className="p-4 hover:bg-blue-800 text-blue-400 font-bold py-2 px-4 rounded"
                        type="button"
                        onClick={goToUserManagement}
                    >
                        User Management
                    </button>
                </div>
            );
        }

        buttons.signOut = (
            <div>
                <button className="bg-blue-400 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={logout}
                >
                    Sign Out
                </button>
            </div>
        );
    }

    return (
        <>
        <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4 flex items-center justify-between">
            <div className="flex items-center justify-between cursor-pointer" onClick={goToDefaultPage}>
                <div>
                    <img className="w-16" src={window.location.origin + '/icon-250.png'} alt="logo" />
                </div>
                <div className="p-4">
                    Propolis - {hostName}
                </div>
            </div>
            {buttons.memberManagement}
            {buttons.userManagement}
            {buttons.signOut}
        </div>
        <ToastContainer
            progressClassName="toastProgress"
            bodyClassName="toastBody"
            autoClose={2500}
        />
        </>
    );
}

export default Header;