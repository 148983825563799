import { useContext } from 'react';
import { UserProvider, UserContext } from './contexts/User';
import { HostProvider } from './contexts/Host';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import Confirmation from './components/Confirmation';
import ResetPasswordRequest from './components/ResetPasswordRequest';
import ResetPassword from './components/ResetPassword';
import Welcome from './components/Welcome';
import MemberManagement from './components/MemberManagement';
import MemberImport from './components/MemberImport';
import UserManagement from './components/UserManagement';

import './App.css';

function Main() {
    const [ user, ] = useContext(UserContext);

    if (user.name) {
        return (
            <Welcome />
        );
    }

    return (
        <Login />
    );
}


function App() {
    return (
        <BrowserRouter>
            <HostProvider>
            <UserProvider>
                <Header />
                <Routes>
                    <Route path="/:host/register" element={<Register />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/:host/:userId/confirm" element={<Confirmation />} />
                    <Route path="/:userId/confirm" element={<Confirmation />} />
                    <Route path="/:host/reset-password/request" element={<ResetPasswordRequest />} />
                    <Route path="/reset-password/request" element={<ResetPasswordRequest />} />
                    <Route path="/:host/:userId/reset-password" element={<ResetPassword />} />
                    <Route path="/:userId/reset-password" element={<ResetPassword />} />

                    <Route path="/:host/members/import" element={<MemberImport />} />
                    <Route path="/members/import" element={<MemberImport />} />
                    <Route path="/:host/members" element={<MemberManagement />} />
                    <Route path="/members" element={<MemberManagement />} />
                    <Route path="/:host/users" element={<UserManagement />} />
                    <Route path="/users" element={<UserManagement />} />

                    <Route path="/:host" element={<Main />} />
                    <Route path="/" element={<Main />} />
                </Routes>
                <Footer />
            </UserProvider>
            </HostProvider>
        </BrowserRouter>
    );
}

export default App;
