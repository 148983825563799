import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';
import { saveToStorage } from "../storage/local";
import Select from 'react-select';
import { toast } from 'react-toastify';

import api from '../utils/api';
import hosts from '../utils/hosts';

const spacer = <>&nbsp;</>;
const VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function ResetPasswordRequest (/*props*/) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, setHost ] = useContext(HostContext);
    const [ email, setEmail ] = useState('');
    const [ response, setResponse ] = useState(null);
    const [ responseSuccess, setResponseSuccess ] = useState(null);
    const [ isRequestingReset, setIsRequestingReset ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const query = new URLSearchParams(location.search);

    let initialHost = hosts.determineInitialHost(host);

    useEffect(() => {
        hosts.hostUpdate({ location, params, initialHost, host, setHost, user, setUser, navigate });
    }, [ host ]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateHost = selectedOption => {
        setHost(api.hosts[selectedOption.value]);
        console.log(`Option selected:`, selectedOption);
    };

    const __DEV__ = query.get("dev") === "true";
    if (__DEV__) {
        console.warn(`---DEV MODE---`);
    }

    let communitySelector;
    if (__DEV__ || hosts.hostsCount > 1) {
        let communityOptions = [];
        for (let key in api.hosts) {
            let host = api.hosts[key];
            if (__DEV__ || host[key].substr(0, 5) !== "test-") {
                communityOptions.push({
                    value: host.key,
                    label: host.name
                });
            }
        }
        communitySelector = <div className="flex items-center">
            <div className="w-min">
                Community:
            </div>
            <div className="w-full p-4">
                <Select
                    value={{
                        value: host.key,
                        label: host.name
                    }}
                    options={communityOptions}
                    onChange={updateHost}
                />
            </div>
        </div>;
    }

    const goToSignin = async () => {
        navigate("/");
    };

    const requestPasswordReset = async () => {
        setIsRequestingReset(true);
        try {
            let values = {
                email: email.trim(),
            };
            console.log(`requesting password reset for ${host.name}`);
            let json = await (
                await fetch(
                    api.resetPasswordRequest(host.url),
                    api.formatRequest({ method: "POST", body: values })
                )
            ).json();
            if (json.reason) {
                setResponse(json.reason);
                setResponseSuccess(false);
                setIsRequestingReset(false);
            } else {
                console.log('password reset request successful');

                saveToStorage("host", host);
                setResponse(json.message);
                setResponseSuccess(true);
                setIsRequestingReset(false);
            }
        } catch (e) {
            console.log(e);
            toast.error("A network error occurred.");
            setIsRequestingReset(false);
        }
    };

    let emailEntered = email.length > 0;
    let emailValidationWarning;
    if (email.length > 0 && !(VALID_EMAIL.test(email))) {
        emailValidationWarning = <p className="text-red-500 text-xs italic">Please enter a valid email address.</p>;
    }

    const tryAgain = async () => {
        setResponse(null);
        setResponseSuccess(null);
    };

    let signUpDisabled = isRequestingReset || !emailEntered || emailValidationWarning;

    const checkForSubmit = (e) => {
        if (!signUpDisabled) {
            if (e.key === 'Enter') {
                requestPasswordReset();
            }
        }
    };

    let signUpOrResponse;
    if (!response) {
        signUpOrResponse = <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={requestPasswordReset}
            disabled={signUpDisabled}
        >
            Request Password Reset
        </button>;
    } else {
        if (responseSuccess) {
            signUpOrResponse = <div className="flex items-center">
                <p className="">Success! {response}</p>
            </div>;
        } else {
            signUpOrResponse = <div className="flex items-center">
                <p className="text-red-500">{response}</p>
                {spacer}
                <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={tryAgain}
                >
                    Try Again
                </button>
            </div>;
        }
    }

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
            <div className="mb-4">
                <label className="block text-grey-800 text-sm font-bold mb-2" htmlFor="email">
                    Email Address
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-800"
                    id="email"
                    type="text"
                    placeholder="Email address"
                    onChange={e => { setEmail(e.target.value); }}
                    onKeyDown={checkForSubmit}
                    value={email}
                ></input>
                {emailValidationWarning}
            </div>
            <div className="flex items-center">
                {signUpOrResponse}
                {spacer}
                <button className="p-4 bg-blue-400 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={goToSignin}
                >
                    Sign In
                </button>
            </div>
            {communitySelector}
        </div>
    );
}

export default ResetPasswordRequest;