const self = {
    // loading to the back, errors to the front, otherwise sort by sortOrder keys
    // sortOrder in the format {'field1': 'asc', 'field2': 'desc' };
    default: (requiredKey, sortOrder) => {
        return (a, b) => {
            let aErrComp, bErrComp;
            // first resolve loading and errors
            if (!a[requiredKey]) {
                // if error must be valued less
                aErrComp = (typeof a.status === "string" && a.status.toLowerCase().indexOf('error') > -1) ? -1 : 1;
                if (!b[requiredKey]) {
                    // if error must be valued less
                    bErrComp = (typeof b.status === "string" && b.status.toLowerCase().indexOf('error') > -1) ? -1 : 1;
                    if (aErrComp < bErrComp) return -1;
                    if (aErrComp > bErrComp) return 1;
                    return 0;
                }
            }
            // a is user, b is not
            if (!b[requiredKey]) {
                bErrComp = (typeof b.status === "string" && b.status.toLowerCase().indexOf('error') > -1);
                if (bErrComp) return 1;
                return -1;
            }
            // both a and b are users, sort according to sortOrder
            for (let key in sortOrder) {
                let dir = sortOrder[key] === "asc" ? 1 : -1;
                if (a[key] && b[key]) {
                    // if both fields have this key and the key is a number then we must
                    // cast to number before comparing
                    let smartCompA = isFinite(a[key]) ? Number(a[key]) : a[key].toLowerCase();
                    let smartCompB = isFinite(b[key]) ? Number(b[key]) : b[key].toLowerCase();
                    if (smartCompA < smartCompB) return dir * -1;
                    if (smartCompA > smartCompB) return dir * 1;
                } else {
                    if (a[key]) return dir * -1;
                    if (b[key]) return dir * 1;
                }
            }
            return 0;
        };
    }
};

export default self;