function Paginator(props) {
    let propsError;

    if (props.size === null || props.size === undefined
        || props.size < 0) {
        propsError = `invalid pagination size property`;
    }

    if (props.offset === null || props.offset === undefined
        || props.offset < 0 || props.offset > props.size) {
        propsError = `invalid pagination offset property`;
    }
    if (!props.limit || props.limit < 1) {
        propsError = `invalid pagination limit property`;
    }

    if (propsError) {
        console.error(propsError, props);
        return (
            <></>
        );
    }

    if (props.size === 0) {
        return (
            <></>
        );
    }

    const currentPage = Math.ceil(props.offset / props.limit) + 1;
    const totalPages = Math.ceil(props.size / props.limit);

    const goToFirstPage = () => {
        props.setPagination({
            offset: 0,
            limit: props.limit,
        });
    };

    const goToPreviousPage = () => {
        props.setPagination({
            offset: props.offset < props.limit ? 0 : props.offset - props.limit,
            limit: props.limit,
        });
    };

    const goToNextPage = () => {
        props.setPagination({
            offset: props.offset > props.size - props.limit ? props.offset : props.offset + props.limit,
            limit: props.limit,
        });
    };

    const goToLastPage = () => {
        props.setPagination({
            offset: (totalPages - 1) * props.limit,
            limit: props.limit,
        });
    };

    const updateLimit = (event) => {
        console.log(event.target.value);
        props.setPagination({
            offset: 0,
            limit: Number(event.target.value),
        });
    };

    return (
        <>
            <div className="px-3 py-4 flex justify-center">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <button
                                    className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                                    disabled={props.offset < 1}
                                    onClick={goToFirstPage}
                                >
                                    &lt;&lt;
                                </button>
                            </td>
                            <td>
                                <button
                                    className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                                    disabled={props.offset < 1}
                                    onClick={goToPreviousPage}
                                >
                                    &lt;
                                </button>
                            </td>
                            <td>
                                Page {currentPage} of {totalPages}
                            </td>
                            <td>
                                (showing
                                <select value={props.limit} onChange={updateLimit}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                of {props.size})
                            </td>
                            <td>
                                <button
                                    className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                                    disabled={props.offset > props.size - props.limit}
                                    onClick={goToNextPage}
                                >
                                    &gt;
                                </button>
                            </td>
                            <td>
                                <button
                                    className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                                    disabled={props.offset > props.size - props.limit}
                                    onClick={goToLastPage}
                                >
                                    &gt;&gt;
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Paginator;