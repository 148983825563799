function ProgressBar(props) {
    return (
        <div className="relative pt-1 py-2">
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-400">
                <div style={{ width: `${props.percentage}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-800"></div>
            </div>
        </div>
    );
}

export default ProgressBar;