import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/User';
import { HostContext } from '../contexts/Host';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { loadFromStorage, saveToStorage } from "../storage/local";
import Select from 'react-select';
import ms from 'ms';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import api from '../utils/api';
import hosts from '../utils/hosts';

const showEye = <FontAwesomeIcon icon={faEye} />;
const hideEye = <FontAwesomeIcon icon={faEyeSlash} />;

const spacer = <>&nbsp;</>;
const VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

let loadedRememberMe = loadFromStorage('rememberMe') || { remember: false, email: null };

let deviceId = loadFromStorage('deviceId');
if (!deviceId) {
    deviceId = `web portal ${uuid()}`;
    saveToStorage('deviceId', deviceId);
}
//console.log(`deviceId:`, deviceId);

function Login (/*props*/) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, setHost ] = useContext(HostContext);
    const [ email, setEmail ] = useState(loadedRememberMe.email || '');
    const [ password, setPassword ] = useState('');
    const [ showPassword, setShowPassword ] = useState(false);
    const [ rememberMe, setRememberMe ] = useState(loadedRememberMe.remember);
    const [ isSigningIn, setIsSigningIn ] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    let query = new URLSearchParams(location.search);

    // identify hosts
    let hostsCount = 0;
    let initialHost = host;
    for (let key in api.hosts) {
        // only count non-test hosts
        if (key.substr(0, 5) !== "test-") {
            initialHost = initialHost || api.hosts[key];
            hostsCount++;
        }
    }

    useEffect(() => {
        hosts.hostUpdate({ location, params, initialHost, host, setHost, user, setUser, navigate });
    }, [ host ]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateHost = selectedOption => {
        let selectedHost = api.hosts[selectedOption.value];
        setHost(selectedHost);
        saveToStorage("host", selectedHost);
        console.log(`Option selected:`, selectedOption);
    };

    const __DEV__ = query.get("dev") === "true";
    if (__DEV__) {
        console.warn(`---DEV MODE---`);
    }

    let communitySelector;
    if (__DEV__ || hostsCount > 1) {
        let communityOptions = [];
        for (let key in api.hosts) {
            let host = api.hosts[key];
            if (__DEV__ || host[key].substr(0, 5) !== "test-") {
                communityOptions.push({
                    value: host.key,
                    label: host.name
                });
            }
        }
        communitySelector = <div className="flex items-center">
            <div className="w-min">
                Community:
            </div>
            <div className="w-full p-4">
                <Select
                    value={{
                        value: host.key,
                        label: host.name
                    }}
                    options={communityOptions}
                    onChange={updateHost}
                />
            </div>
        </div>;
    }

    const goToSignup = async () => {
        navigate("/register");
    };

    const goToPasswordReset = async () => {
        navigate("/reset-password/request");
    };

    const login = async () => {
        setIsSigningIn(true);
        try {
            let values = {
                email: email.trim(),
                password,
                deviceId
            };
            let authenticationTimestamp = new Date().getTime();
            console.log(`logging in to ${host.name} from ${values.deviceId}`);
            let json = await (
                await fetch(
                    api.login(host.url),
                    api.formatRequest({ method:"POST", body: values })
                )
            ).json();
            if (json.reason) {
                toast.error(json.reason);
                setIsSigningIn(false);
            } else {
                console.log('login successful');
                // convert authTokenExpiration and refreshTokenExpiration to local timestamps
                json.authTokenExpiration = authenticationTimestamp + ms(json.authTokenExpiration);
                json.refreshTokenExpiration = authenticationTimestamp + ms(json.refreshTokenExpiration);
                json.host = host.key;

                saveToStorage("userSession", json);
                saveToStorage("host", host);
                if (rememberMe) {
                    loadedRememberMe = {
                        email: json.email,
                        remember: true
                    };

                } else {
                    loadedRememberMe = {
                        remember: false,
                        email: null
                    };
                    setEmail('');
                }
                saveToStorage('rememberMe', loadedRememberMe);
                setIsSigningIn(false);
                setUser(json);
            }
        } catch (e) {
            console.log(e);
            toast.error("A network error occurred.");
            setIsSigningIn(false);
        }
    };

    let emailEntered = email.length > 0;
    let emailValidationWarning;
    if (email.length > 0 && !(VALID_EMAIL.test(email))) {
        emailValidationWarning = <p className="text-red-500 text-xs italic">Please enter a valid email address.</p>;
    }

    let passwordEntered = password.length > 0;
    let passwordValidationWarning;
    if (passwordEntered && password.length < 8) {
        passwordValidationWarning = <p className="text-red-500 text-xs italic">Password must be at least 8 characters in length.</p>;
    }

    const rememberMeHandler = (e) => {
        setRememberMe(e.target.checked);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const linkToForgotPassword = (
        <button className="p-4 hover:bg-blue-800 text-blue-400 font-bold py-2 px-4 rounded"
            type="button"
            onClick={goToPasswordReset}
        >
            Forgot Password?
        </button>
    );

    let signInDisabled = isSigningIn || !emailEntered || !passwordEntered || emailValidationWarning || passwordValidationWarning;

    const checkForSubmit = (e) => {
        if (!signInDisabled) {
            if (e.key === 'Enter') {
                login();
            }
        }
    };

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
            <div className="mb-4">
                <label className="block text-grey-800 text-sm font-bold mb-2" htmlFor="email">
                    Email Address
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-800"
                    id="email"
                    type="text"
                    placeholder="Email address"
                    onChange={e => { setEmail(e.target.value); }}
                    onKeyDown={checkForSubmit}
                    value={email}
                ></input>
                {emailValidationWarning}
            </div>
            <div className="mb-6 align-middle">
                <label className="block text-grey-800 text-sm font-bold mb-2" htmlFor="password">
                    Password
                </label>
                <div className="relative w-full align-middle">
                    <div className="absolute right-0 flex items-center px-2 py-3 align-middle">
                        <input className="hidden align-middle"
                            id="toggle"
                            type="checkbox"
                            onClick={toggleShowPassword}
                        />
                        <label className="rounded px-2 text-sm text-black font-mono cursor-pointer align-middle"
                            htmlFor="toggle"
                            title={showPassword ? "Hide password" : "Show password"}
                        >
                            {showPassword ? hideEye : showEye}
                        </label>
                    </div>
                    <input className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3 align-middle"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        onChange={e => { setPassword(e.target.value); }}
                        onKeyDown={checkForSubmit}
                        autoComplete="off"
                    />
                </div>
                {passwordValidationWarning}
                {linkToForgotPassword}
            </div>
            <div className="flex items-center">
                <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={login}
                    disabled={signInDisabled}
                >
                    Sign In
                </button>
                {spacer}
                <button className="p-4 bg-blue-400 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={goToSignup}
                >
                    Sign Up
                </button>
                {spacer}
                <div className="p-4 bg-blue-400 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">
                    <label className="flex items-baseline">
                        <input className="form-checkbox"
                            type="checkbox"
                            defaultChecked={loadedRememberMe.remember}
                            onChange={rememberMeHandler}
                        />
                        <span className="ml-2">Remember Me</span>
                    </label>
                </div>
            </div>
            {communitySelector}
        </div>
    );
}

export default Login;