import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import zxcvbn from 'zxcvbn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';
import { loadFromStorage } from '../storage/local';

import api from '../utils/api';
import hosts from '../utils/hosts';

const showEye = <FontAwesomeIcon icon={faEye} />;
const hideEye = <FontAwesomeIcon icon={faEyeSlash} />;

const spacer = <>&nbsp;</>;

function ResetPassword (/*props*/) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, setHost ] = useContext(HostContext);
    const [ password, setPassword ] = useState('');
    const [ showPassword, setShowPassword ] = useState(false);
    const [ isResettingPassword, setIsResettingPassword ] = useState(false);
    const [ response, setResponse ] = useState(null);
    const [ responseSuccess, setResponseSuccess ] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const query = new URLSearchParams(location.search);

    let initialHost = hosts.determineInitialHost(host);

    useEffect(() => {
        hosts.hostUpdate({ location, params, initialHost, host, setHost, user, setUser, navigate });
    }, [ host ]); // eslint-disable-line react-hooks/exhaustive-deps

    let resetPasswordParams = {
        userId: params.userId,
        otp: query.get("token")
    };

    const __DEV__ = query.get("dev") === "true";
    if (__DEV__) {
        console.warn(`---DEV MODE---`);
    }

    const goToSignin = async () => {
        navigate("/");
    };

    const resetPasswordPostRequest = async (values) => {
        // override from storage required for email-linked pages
        let intendedHost = loadFromStorage("host");
        if (__DEV__) {
            console.log(`resetting password with ${intendedHost.key} (mocked response)`);
            return {
                "success": true,
                "message": "Password reset successful."
            };
        }
        console.log(`resetting password with ${intendedHost.key}`);
        return await (
            await fetch(
                api.resetPassword(intendedHost.url),
                api.formatRequest({ method: "POST", body: values })
            )
        ).json();
    };

    const resetPassword = async() => {
        setIsResettingPassword(true);
        let json = await resetPasswordPostRequest({
            ...resetPasswordParams,
            password,
        });

        if (json.reason) {
            setResponse(json.reason);
            setResponseSuccess(false);
        } else {
            setResponse(json.message);
            setResponseSuccess(true);
        }
        setIsResettingPassword(false);
    };

    let passwordEntered = password.length > 0;
    let passwordValidationWarning;
    let passwordStrength;
    if (passwordEntered) {
        if (password.length < 8) {
            passwordValidationWarning = <p className="text-red-500 text-xs italic">Password must be at least 8 characters in length.</p>;
        } else {
            // show strength + message if insufficient
            let strength = zxcvbn(password).score;
            switch (strength) {
                case 2:
                    passwordStrength = <p className="text-pink-400 text-xs italic">Password strength: Weak</p>;
                    break;
                case 3:
                    passwordStrength = <p className="text-yellow-600 text-xs italic">Password strength: Risky</p>;
                    break;
                case 4:
                    passwordStrength = <p className="text-green-600 text-xs italic">Password strength: Strong</p>;
                    break;
                default:
                    passwordStrength = <p className="text-red-500 text-xs italic">Password strength: Weak</p>;
            }
            if (strength < 3) {
                passwordValidationWarning = <p className="text-red-500 text-xs italic">Password strength does not meet minimum safety requirements.</p>;
            }
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const tryAgain = async () => {
        setResponse(null);
        setResponseSuccess(null);
    };

    let resetPasswordDisabled = isResettingPassword || !passwordEntered || passwordValidationWarning;

    const checkForSubmit = (e) => {
        if (!resetPasswordDisabled) {
            if (e.key === 'Enter') {
                resetPassword();
            }
        }
    };

    let resetPasswordOrResponse;
    if (!response) {
        resetPasswordOrResponse = <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={resetPassword}
            disabled={resetPasswordDisabled}
        >
            Reset Password
        </button>;
    } else {
        if (responseSuccess) {
            resetPasswordOrResponse = <div className="flex items-center">
                <p className="">Success! {response}</p>
            </div>;
        } else {
            resetPasswordOrResponse = <div className="flex items-center">
                <p className="text-red-500">{response}</p>
                {spacer}
                <button className="p-4 bg-blue-400 hover:bg-blue-800 disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={tryAgain}
                >
                    Try Again
                </button>
            </div>;
        }
    }

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
            <div className="mb-6">
                <label className="block text-grey-800 text-sm font-bold mb-2" htmlFor="password">
                    Password
                </label>
                <div className="relative w-full">
                    <div className="absolute right-0 flex items-center px-2 py-3">
                        <input className="hidden js-password-toggle"
                            id="toggle"
                            type="checkbox"
                            onClick={toggleShowPassword}
                        />
                        <label className="rounded px-2 text-sm text-black font-mono cursor-pointer js-password-label"
                            htmlFor="toggle"
                            title={showPassword ? "Hide password" : "Show password"}
                        >
                            {showPassword ? hideEye : showEye}
                        </label>
                    </div>
                    <input className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        onChange={e => { setPassword(e.target.value); }}
                        onKeyDown={checkForSubmit}
                        autoComplete="off"
                    />
                </div>
                {passwordStrength}
                {passwordValidationWarning}
            </div>
            <div className="flex items-center">
                {resetPasswordOrResponse}
                {spacer}
                <button className="p-4 bg-blue-400 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={goToSignin}
                >
                    Sign In
                </button>
            </div>
        </div>
    );
}

export default ResetPassword;