
const self = {
    ROLE: {
        ADMIN: "admin",
        VIEW_USERS: "view-users",
        EDIT_USERS: "edit-users",
        VIEW_MEMBERS: "view-members",
        EDIT_MEMBERS: "edit-members",
        IMPORT_MEMBERS: "import-members",
        CREATE_MEMBERS: "create-members",
        DELETE_MEMBERS: "delete-members",
        VIEW_MEMORIALS: "view-memorials",
        EDIT_MEMORIALS: "edit-memorials",
        IMPORT_MEMORIALS: "import-memorials",
        CREATE_MEMORIALS: "create-memorials",
        DELETE_MEMORIALS: "delete-memorials"
    },
    hasRole: (user, roles) => {
        if (user && Array.isArray(user.roles)) {
            if (user.roles.indexOf(self.ROLE.ADMIN) > -1) {
                return true;
            }
            for (let r in roles) {
                if (user.roles.indexOf(roles[r]) > -1) {
                    return true;
                }
            }
        }
        return false;
    },
};

export default self;
