import { deleteFromStorage, saveToStorage } from "../storage/local";
import api from './api';

const self = {
    determineInitialHost: (host) => {
        // identify hosts
        self.hostsCount = 0;
        self.initialHost = host;
        for (let key in api.hosts) {
            // only count non-test hosts
            if (key.substr(0, 5) !== "test-") {
                self.initialHost = self.initialHost || api.hosts[key];
                self.hostsCount++;
            }
        }
        return self.initialHost;
    },
    hostUpdate: async ({ location, params, initialHost, host, setHost, user, setUser, navigate }) => {
        console.log(`hostUpdate called for`, location);
        params = params || {};
        try {
            if (params.host) {
                console.log(`host ${params.host} specified`);
                let hostEntry = api.hosts[params.host];
                if (hostEntry) {
                    // host entry doesn't match current host
                    if (!host || hostEntry.key !== host.key) {
                        console.log(`detected host mismatch ${host.key}  ${hostEntry.key}`);
                        // change host
                        saveToStorage("host", hostEntry);
                        setHost(hostEntry);
                        // invalidate user
                        deleteFromStorage("userSession");
                        setUser({});
                        // redirect to login screen unless we're on an open page
                        // eg. registration / confirmation / password reset
                        // clear host from url
                        let path = location.pathname;
                        let openPaths = [
                            '/register',
                            '/confirm',
                            '/reset-password/request',
                            '/reset-password',
                        ];
                        // direct path is everything after the host key
                        let directPath = path.substr(path.indexOf(hostEntry.key) + hostEntry.key.length);
                        let isOpenPath = false;
                        for (let i in openPaths) {
                            if (directPath === openPaths[i]
                                || directPath.substr(-openPaths[i].length) === openPaths[i]
                            ) {
                                isOpenPath = true;
                                navigate(directPath + location.search);
                            }
                        }
                        if (!isOpenPath) {
                            navigate('/' + location.search);
                        }
                    } else {
                        // host entry matches current host
                        console.log(`host entry matches`);
                        // clear host from url
                        let path = location.pathname;
                        console.log(`path ${path}`);
                        // direct path is everything after the host key
                        let directPath = path.substr(path.indexOf(hostEntry.key) + hostEntry.key.length);
                        if (directPath.length === 0) {
                            directPath = '/';
                        }
                        console.log(`navigating to ${directPath + location.search}`);
                        navigate(directPath + location.search);
                        // verify host key and host match
                        //self.validateUserHost({ host, user, setUser, navigate });
                    }
                } else {
                    console.error(`invalid host ${params.host}`);
                }
            } else {
                console.log(`no host detected in url`);
                if (!host) {
                    setHost(initialHost);
                    saveToStorage("host", initialHost);
                }
                // verify host key and host match
                self.validateUserHost({ host, user, setUser, navigate });
            }
        } catch (err) {
            console.error(`error during hostUpdate`, err);
        }
    },
    validateUserHost: ({ host, user, setUser, navigate }) => {
        if (user.name && host.key !== user.host) {
            console.log(`invalid user / host detected, signing out`);
            // invalidate user
            deleteFromStorage("userSession");
            setUser({});
            // return to login screen
            navigate('/');
        }
    },
};

export default self;