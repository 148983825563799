import api from './api';
import { deleteFromStorage, loadFromStorage, saveToStorage } from "../storage/local";

const self = {
    isRefreshing: false,
    refresher: null,
    refresh: ({host, user}) => {
        if (self.isRefreshing) {
            return self.refresher;
        }
        self.isRefreshing = true;
        self.refresher = new Promise((resolve, reject) => {
            let currentTime = Date.now();
            try {
                // if refresh token has expired
                if (user.refreshTokenExpiration - currentTime <= 0) {
                    throw new Error('page refreshed with expired refresh token');
                }
                // if user did not select "remember me"
                if (!loadFromStorage('rememberMe').remember) {
                    throw new Error('page refreshed with remember me not flagged');
                }
                // auth token has expired, refresh token has not expired, "remember me" selected
                console.log(`refreshing token for ${host.key}...`);
                api.refreshAuthenticationToken(host, user)
                .then((userSession) => {
                    saveToStorage("userSession", userSession);
                    self.isRefreshing = false;
                    resolve(userSession);
                })
                .catch(err => {
                    self.isRefreshing = false;
                    reject(err);
                });
            } catch (err) {
                deleteFromStorage('userSession');
                self.isRefreshing = false;
                reject(err);
            }
        });
        return self.refresher;
    },
};

export default self;