import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
//import ms from 'ms';
import { toast } from 'react-toastify';
import moment from 'moment';

import { /*HebrewCalendar,*/ HDate, /*Location, Event,*/ Zmanim } from '@hebcal/core';

import { HostContext } from '../contexts/Host';
import { UserContext } from '../contexts/User';

import api from '../utils/api';
import hosts from '../utils/hosts';
import tokenRefresh from '../utils/tokenRefresh';

// on each render, the sunset timeout will be reset to trigger a
// render at sunset so that the hebrew date will be updated
let sunsetTimeout;

function Welcome (/*props*/) {
    const [ user, setUser ] = useContext(UserContext);
    const [ host, setHost ] = useContext(HostContext);
    const [, setIsSunset ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const query = new URLSearchParams(location.search);

    const __DEV__ = query.get("dev") === "true";
    if (__DEV__) {
        console.warn(`---DEV MODE---`);
    }

    useEffect(() => {
        hosts.hostUpdate({ location, params, host, setHost, user, setUser, navigate });
    }, [ host ]); // eslint-disable-line react-hooks/exhaustive-deps

    let gregorianDate = moment();
    let hebrewDate = new HDate(gregorianDate.toDate());

    let currentTimeElement;

    // if we have a host's location we can determine the time of the sunset,
    // and if it's currently after sunset then we are on the next hebrew date
    let hostLocation = api.hosts[host.key].location;
    let sunset = moment(
        new Zmanim(
            new Date(),
            hostLocation.latitude,
            hostLocation.longitude
        ).sunset()
    );
    let isBeforeSunset = sunset.isAfter(gregorianDate);
    if (isBeforeSunset) {
        // before sunset, we reset the sunset timeout
        // to trigger a render at / after sunset
        if (sunsetTimeout) {
            clearTimeout(sunsetTimeout);
        }
        // set the sunset timeout to expire at sunset
        // we only do this if we have less than a minute to go,
        // otherwise android devices error out
        if (sunset.diff(moment()) < (60 * 1000)) {
            sunsetTimeout = setTimeout(() => {
                setIsSunset(true);
            }, sunset.diff(moment()));
        }
    } else {
        // after sunset, we want to display the next date
        hebrewDate = hebrewDate.next();
    }

    currentTimeElement = (
        <>
            <div className="px-3 py-1 flex center content-center justify-center font-semibold">
                {gregorianDate.format('dddd MMMM Do YYYY')} ({isBeforeSunset ? 'before' : 'after'} sunset: {sunset.format('h:mmA')})
            </div>
            <div className="px-3 py-1 flex center content-center justify-center font-semibold">
                {hebrewDate.toString()}
            </div>
            <div className="px-3 py-1 flex center content-center justify-center font-semibold">
                {hebrewDate.renderGematriya()}
            </div>
        </>
    );

    //console.log(`session token: ${ms(user.authTokenExpiration - Date.now())} remaining`);

    if (tokenRefresh.isRefreshing) {
        return (
            <>
                <div className="px-3 py-4 flex justify-center">
                    Session expired, re-authenticating...
                </div>
                {currentTimeElement}
            </>
        );
    }

    // token is not currently being refreshed
    const refreshAuthToken = async () => {
        if (!tokenRefresh.isRefreshing) {
            tokenRefresh.refresh({ host, user })
            .then((userSession) => {
                setUser(userSession);
                console.log(`refresh complete`);
            })
            .catch(err => {
                toast.error(err);
                setUser({});
            });
        }
    };

    if (user.authTokenExpiration - Date.now() <= 0) {
        refreshAuthToken();
    }

    const fakeRefreshRequired = async () => {
        setUser({
            ...user,
            authTokenExpiration: Date.now()
        });
    };

    let fakeRefreshRequiredButton;
    if (__DEV__) {
        fakeRefreshRequiredButton = (
            <div className="flex items-center">
                <button className="p-4 hover:bg-blue-800 text-blue-400 font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={fakeRefreshRequired}
                >
                    Fake Refresh
                </button>
            </div>
        );
    }

    return (
        <>
            <div className="px-3 py-4 flex justify-center">
                Welcome to the Community Management Portal {host.name ? `for ${host.name}` : ''}
            </div>
            <br />
            {currentTimeElement}
            <div className="px-3 py-4 flex justify-center">
                {fakeRefreshRequiredButton}
            </div>
        </>
    );
}

export default Welcome;